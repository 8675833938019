import React, { Component } from 'react';
import { Dimmer, Loader, Message, Button } from 'semantic-ui-react'
import { withCookies } from 'react-cookie';
import { CountryDropdown } from 'react-country-region-selector';
import { confirmAlert } from 'react-confirm-alert';
import TableVille from './TableVille';
import FormVille from './FormVille';
import Searcher from '../Searcher';
import 'react-confirm-alert/src/react-confirm-alert.css';


class Ville extends Component {
    
    state = { 
        token: this.props.cookies.get('FC_token'),
        perm_level: this.props.cookies.get('FC_perm_lvl'),
        requiredPermLevel: 5,
        villes: null,
        territoires: [],
        selectedVille: null,
        errors: null,
        edit: false,
    }

    componentDidMount(){
        this.loadVilles()
        this.loadTerritoires();
    }

    loadVilles = () => {
        this.setState({loading: true, selectedVille: null})
        fetch(`${process.env.REACT_APP_API_URL}/api/villes/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => {
              this.setState({loading: false, villes: resp})
            })
          .catch(error => console.log(error))
    }

    loadTerritoires = () => {
        this.setState({loading: true, selectedVille: null})
        fetch(`${process.env.REACT_APP_API_URL}/api/territoires/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => {
              this.setState({loading: false, territoires: resp})
            })
          .catch(error => console.log(error))
    }

    setVilles = (villes) => {
        this.setState({villes: villes})
    }

    saveVille = ville => event => {
        event.preventDefault();
        let sv = this.state.selectedVille
        sv.nom = ville.nom
        sv.territoire = ville.territoire
        sv.code_postal = ville.code_postal
        sv.pays = ville.pays
        this.setState({selectedVille: sv})

        let method = this.state.edit ? "PUT" : "POST"
        let url = this.state.edit ? `${process.env.REACT_APP_API_URL}/api/villes/${this.state.selectedVille.id}/` :
                                    `${process.env.REACT_APP_API_URL}/api/villes/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedVille)
        }).then(response => response.json() )
        .then(resp => {
            if(Array.isArray(resp.nom)){
                this.setState({errors: resp.nom})
            } else {
                if(this.state.edit){
                    this.loadVilles();
                    this.setState({selectedVille: null, edit: false, error: null});
                } else {
                    this.setState({villes: [...this.state.villes, resp], selectedVille: null, edit: false});
                }
            }
        })
        .catch(error => console.log(error))
    }

    cancelForm = (event) => {
        event.preventDefault();
        this.setState({selectedVille: null, edit: false, error: null});
    }

    editVille = ville => event => {
        event.preventDefault();
        this.setState({selectedVille: ville, edit:true})
    }

    confirmDelete = event => {
        event.preventDefault();
        confirmAlert({
            title: "Confirmer supression",
            message: `Etes vous sur de vouloir supprimer la ville ${this.state.selectedVille.nom} ?`,
            buttons: [
                {label: "Oui", onClick: () => this.deleteVille(event)},
                {label: "Non", onClick: () => this.cancelForm(event)},
            ]
        });

    }
    
    deleteVille = event => {
        event.preventDefault();
        let method = "DELETE"
        let url = `${process.env.REACT_APP_API_URL}/api/villes/${this.state.selectedVille.id}/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedVille)
        })
        .then(resp => {
            if(resp.ok){
                const villes = this.state.villes.filter(ville => ville.id !== this.state.selectedVille.id);
                this.setState({villes: villes, edit: false, selectedVille: null})
            } else {
                this.setState({errors: "Vous ne pouvez pas supprimer cette Ville car elle contient encore au moins un Site."})
            }
        })
    }

    cleanErrors = () => {
        this.setState({errors: null})
    }

    newVille = () => {
        if (this.props.choosenTerritoire !== null){
            this.setState({edit: false, selectedVille: {nom:'', code_postal:'', territoire: this.props.choosenTerritoire.id}})
        } else {
            this.setState({edit: false, selectedVille: {nom:'', code_postal:'', territoire: ''}})
        }
    }

    getVilles = (t) => {
        if(this.state.villes !== null){
            let villes = this.state.villes.filter(ville => {
                if(t !== null){
                    if(ville.territoire === t.id){
                        return ville
                    }                                            
                } else {
                    return ville
                }
            })
            return villes
        }
        return null
    }

    chooseVille = ville => {
        this.props.loadSections(ville, 'villes', 5);
    }

    getNomTerritoire = (ville) => {
        let territoires = this.state.territoires.filter(territoire => territoire.id === ville.territoire)
        if (territoires.length > 0) {
            return territoires[0].nom
        } else {
            return ""
        }
    }

    render() { 
        const t = this.props.choosenTerritoire
        const villes = this.getVilles(t);
        return (
            <div>
                <div style={{textAlign: 'left'}}>
                    {/* FORM VILLES */}
                    {this.state.perm_level >= this.state.requiredPermLevel && this.state.selectedVille !== null ? (
                        <FormVille 
                            ville={this.state.selectedVille} 
                            territoire={this.props.choosenTerritoire}
                            territoires={this.state.territoires}
                            cancelForm={this.cancelForm} 
                            edit={this.state.edit}
                            saveVille={this.saveVille}
                            deleteVille={this.confirmDelete}
                            errors={this.state.errors}
                            cleanErrors={this.cleanErrors}
                         />
                    ) : ( 
                        <div>
                            {this.state.perm_level >= this.state.requiredPermLevel ? (
                                <div>
                                    <div style={{margin: '4rem'}}>
                                        <Button positive size='tiny' onClick={this.newVille}>CREER VILLE</Button>
                                    </div>
                                    {/* <Searcher 
                                        items={this.state.villes} 
                                        setItems={this.setVilles}
                                        resetSearch={this.loadVilles}
                                    /> */}
                                    <TableVille 
                                        villes={villes}
                                        editVille={this.editVille} 
                                        chooseVille={this.chooseVille}
                                        getNomTerritoire={this.getNomTerritoire}
                                    />
                                </div>
                            ) : null }
                        </div>
                    )}
                    
                </div>
            </div>
         );
    }
}
 
export default withCookies(Ville);