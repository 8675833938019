import React, { Component } from 'react';
import { withCookies } from 'react-cookie';


class Login extends Component {
    state = {
        credentials: {
            username: '',
            password: '',
            password2: '',
            email: '',
        },
        selectedLangue: '',
        langues: [],
        errorMsg: '',
        validMsg: '',
        width: null,
    }

    inputChanged = (event) => {
        let cred = this.state.credentials
        cred[event.target.name] = event.target.value
        this.setState({credentials: cred})
    }
    
    componentDidMount = () => {
        if (this.props.cookies.get('FC_token') !== undefined){
            window.location.href = "/userboard"
        }
        this.setState({width: window.innerWidth})
        this.loadLangues();
    }

    loadLangues = () => {
        this.setState({langues: [], selectedLangue: ''} );
        fetch(`${process.env.REACT_APP_API_URL}/api/langues/`, {
            method: 'GET',
        }).then(response => response.json())
          .then(resp => this.setState({langues: resp}))
          .catch(error => console.log(error))
    }

    signup = event => {
        event.preventDefault();
        if(this.state.credentials.password === this.state.credentials.password2){
            let creds = this.state.credentials
            creds.email = creds.username
            this.setState({credentials: creds})
            fetch(`${process.env.REACT_APP_API_URL}/api/users/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    username: this.state.credentials.email,
                    email: this.state.credentials.email,
                    password: this.state.credentials.password,
                    langue: this.state.selectedLangue.id,
                })
                }).then(response => response.json())
                .then(resp =>{
                    if(!resp.hasOwnProperty('id') && resp.hasOwnProperty('email')){
                        this.setState({errorMsg: resp.email})
                    } else {
                        this.setState({validMsg: 'Votre compte a bien été crée.'});
                    }
                })
                .catch(error => console.log(error))
        } else {
            this.setState({errorMsg: "Les mots de passes doivent etre identiques.",
                            password: '', password2: ''})

        }
    }


    selectLangue = event => {
        let langue_id = event.target.value
        let xlang = this.state.langues.filter(l => l.id == langue_id)
        this.setState({selectedLangue: xlang[0]})
    }

    toLogin = () => {
        window.location.href = '/login'
    }


    render() { 
        let disabled_register = this.state.credentials.username.length === 0 ||
                                this.state.credentials.password.length === 0 ||
                                this.state.credentials.password2.length === 0 ||
                                this.state.selectedLangue.length === 0
        return ( 
            <div style={styles.mainContainer}>
                {this.state.width > 900 ? (
                    <h1 style={{color: '#008081', fontSize: "5em"}}>QUESAQUO</h1>
                ) : (
                    <h1 style={{color: '#008081', fontSize: "3em"}}>QUESAQUO</h1>
                )}
                <div style={styles.loginContainer}>
                    <h1>Créez votre compte</h1>
                    <form className="ui form">
                        <div className="field">
                            <label>Email</label>
                            <input type="email" name='username'
                            value={this.state.credentials.username} 
                            onChange={this.inputChanged} />
                        </div>
                        <div className="field">
                            <label>Mot de passe</label>
                            <input type="password" name='password' 
                            value={this.state.credentials.password} 
                            onChange={this.inputChanged} />
                        </div>
                        <div className="field">
                            <label>Confirmation Mot de passe</label>
                            <input type="password" name='password2' 
                            value={this.state.credentials.password2} 
                            onChange={this.inputChanged} />
                        </div>
                        <select className="ui fluid dropdown" onChange={this.selectLangue} value={this.state.credentials.langue}>
                            <option value=''>Choisir une Langue</option>
                            {this.state.langues.map(langue => {
                                return (<option key={langue.id} value={langue.id}>{langue.nom}</option>)
                            })}
                        </select>
                        <br/>
                        <button onClick={this.signup} className="ui button primary" disabled={disabled_register}>Creer le compte</button>
                        <div className="ui horizontal divider">Vous etes deja inscrit ?</div>
                        <span onClick={this.toLogin} className="click-span">Retour à la page de connexion</span>
                    </form>
                    {this.state.errorMsg !=='' ? (
                        <div className="ui error message">
                            <i className="close icon" onClick={() => this.setState({errorMsg: ''})}></i>
                            <div className="header">{this.state.errorMsg}</div>
                        </div>
                    ) : null}
                    {this.state.validMsg !== '' ? (
                        <div className="ui success message">
                            <i className="close icon" onClick={() => this.setState({validMsg: ''})}></i>
                            <div className="header">{this.state.validMsg}</div>
                        </div>
                    ) : null}
                </div>
            </div>
         );
    }
}

var styles = {
    mainContainer:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100vh"
    },
    title:{
        color: '#008081',
        fontSize: "5em"
    },
    loginContainer: {
        backgroundColor: '#008081',
        padding: "3vh",
        color: 'white',
        width: '70%',
    },
}
 
export default withCookies(Login);