import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

class Board extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="thirteen wide column board">
                <div style={{'padding': '5em'}}>
                    <h1>Dashboard</h1>
                    <p>Coming soon...</p>
                </div>
            </div>
         );
    }
}
 
export default withCookies(Board);