import React, {useState, useEffect } from 'react';
import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Item } from 'semantic-ui-react';

Leaflet.Icon.Default.imagePath =
'../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export default function ExploreMap(props){

    const [lat, setLat] = useState(43.257972)
    const [lng, setLng] = useState(1.202683)
    const [zoom, setZoom] = useState(13)

    const [allPis, setAllPis] = useState([])
    const [visiblePis, setVisiblePis] = useState([])

    useEffect(() => {
        loadAllPis();
    }, [])

    const loadAllPis = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/points_interet/`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(resp=>resp.json())
          .then(resp => {
            setAllPis(resp)
        })
        .catch(error => console.log(error))
    }

    const filerPiList = event => {
        setVisiblePis([])
        let bounds = event.target.getBounds()
        let northEast = bounds.getNorthEast()
        let southWest = bounds.getSouthWest()
        console.log(allPis)
        let vpi = allPis.filter(pi => {
            if(pi.lat <= northEast.lat && pi.lat >= southWest.lat && pi.lng <= northEast.lng && pi.lng >= southWest.lng){
                return pi
            }
        })
        setVisiblePis(vpi)
    }

    return (
        <div>
            <div style={styles.mainContainer}>
                <div style={styles.mapContainer}>
                    <Map center={[lat, lng]}  zoom={zoom} style={styles.map}
                        onZoomend={filerPiList} onMoveend={filerPiList}>
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {visiblePis.map(pi => {
                            return (
                                <Marker position={[pi.lat, pi.lng]} key={pi.id} onMouseOver={(e) => { e.target.openPopup()}} onMouseOut={(e) => { e.target.closePopup()}}>
                                    <Popup>
                                        <h3>{pi.nom}</h3>
                                    </Popup>
                                </Marker>
                            )
                        })}
                    </Map>
                </div>
                {/* <div style={styles.listContainer}>
                    {visiblePis.map(pi => {
                        return (
                            <div style={styles.piListItem} key={pi.id} className="ui">
                                <h3 className="ui">{pi.nom}</h3>
                                <p>{pi.ville}</p>
                            </div>
                        )
                    })}
                </div> */}
            </div>
        </div>
    )
}

var styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '75vh',
        border: '1px solid lightgray',
    },
    mapContainer: {
        width: '100%',
    },
    map : {
        height: '100%',
        width: '100%',
    },
    listContainer: {
        width: '25%',
        borderLeft: '1px solid lightgray',
    },
    piListItem: {
        padding: '1rem',
        textAlign: 'center',
        borderBottom: '1px solid lightgray',
    }
}