import React, { useState, useEffect } from 'react';
import { Popup, Icon, Dimmer, Loader, Message, Item,  CardGroup, Card, CardHeader, CardDescription, CardContent, Button } from 'semantic-ui-react'

export default function TableMonument(props){

    const [monuments, setMonuments] = useState(null);

    useEffect(() => {
        setMonuments(props.monuments)
    }, [props.monuments])

    return (
        <React.Fragment>
            {monuments !== null ? (
                <div className="ressouce_table">
                    {monuments.length === 0 ? (
                        <Message>
                            <Message.Header>Vous n'avez pas encore de monuments.</Message.Header>
                        </Message>
                    ) : (
                        <div style={{ textAlign: 'left'}}>
                            <CardGroup>
                                {monuments.map(monument => {
                                    return <Card fluid color='#118080' >
                                        <CardContent onClick={e => {props.chooseMonument(monument)}} style={{cursor: 'pointer'}}>
                                            <CardHeader>{monument.nom}</CardHeader>
                                        </CardContent>
                                        <CardContent extra>
                                            <div className='ui one buttons'>
                                                <Button basic color='green' onClick={props.editMonument(monument)}>
                                                    Modifier
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                })}
                            </CardGroup>
                        </div>
                    )}
                </div>
            ): (
                <Dimmer active inverted>
                    <Loader inverted>Loading Monuments</Loader>
                </Dimmer>
            )}
        </React.Fragment>
    )
}

var styles = {
    editIcon: {
        marginLeft: '1em',
    }
}