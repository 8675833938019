import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import Navbar from '../Navbar';
import { Header, Form, Divider, Button, Message } from 'semantic-ui-react';

export default function UserProfile(props){

    const [cookies, setCookie, removeCookie] = useCookies(['']);
    const [langues, setLangues] = useState([]);
    const [userLangue, setUserLangue] = useState([]);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [langueMsg, setLangueMsg] = useState(null);

    useEffect(() => {
        if ("FC_token" in cookies === false){
            window.location.href = "/login"
        } else {
            loadLangues();   
            getUserLangue();   
        }
    }, [])

    const getUserLangue = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/users/get_langue/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${cookies['FC_token']}`,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json())
          .then(resp => setUserLangue(resp.langue))
          .catch(error => console.log(error))
    }

    const loadLangues = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/langues/`, {
            method: 'GET',
        }).then(response => response.json())
          .then(resp => setLangues(resp))
          .catch(error => console.log(error))
    }

    const handleLangueSelect = event => {
        fetch(`${process.env.REACT_APP_API_URL}/api/users/set_langue/`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${cookies['FC_token']}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({langue: event.target.value})
        }).then(response => response.json())
          .then(resp => setUserLangue(resp.langue))
          .catch(error => console.log(error))
    }

    const changePwd = () => {
        if(newPassword1 !== newPassword2){
            setErrorMsg('Les nouveaux mots de passe ne correspondent pas !')
            setNewPassword2('')
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/api/users/modify_password/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${cookies['FC_token']}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    old_password: oldPassword,
                    new_password: newPassword2,
                })
            })
            .then(resp => resp.json()).catch(error => console.log(error))
            .then(resp => {
                if ("error" in resp){
                    setErrorMsg(resp.error)
                    setSuccessMsg(null)
                } else {
                    setErrorMsg(null)
                    setSuccessMsg(resp.message)
                    setOldPassword('')
                    setNewPassword1('')
                    setNewPassword2('')
                }
            })
        }
    }

    const handleLangueValid = () => {
        setLangueMsg("Votre langue a bien été changée")
    }


    return (
        <React.Fragment>
            <Navbar />
            <div className="ui container" style={styles.profileContainer}>
                <div style={styles.centerForm}>
                    <Header content="Mon Profil" />
                    <Divider />
                    <Form error={errorMsg !== null} success={successMsg !== null}>
                        <Header content="Changer de Langue" />  
                        <Form.Field>
                            <label>Langue</label>
                            <select className="ui fluid dropdown" onChange={e => handleLangueSelect(e)} value={userLangue.id}>
                                {langues.map(langue => {
                                    return (<option key={langue.id} value={langue.id}>{langue.nom}</option>)
                                })}
                            </select>
                        </Form.Field>
                        <Button positive size="small" onClick={handleLangueValid}>Valider langue</Button>
                        {langueMsg === null ? null : <Message positive header={langueMsg} onDismiss={ e => {setLangueMsg(null)}} />}
                        <Divider />
                        <Header content="Modifier Mot de Passe" />
                        <Form.Field>
                            <label>Ancien Mot de Passe</label>
                            <input type="password" value={oldPassword} onChange={e => {setOldPassword(e.target.value)}}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Nouveau Mot de Passe</label>
                            <input type="password" value={newPassword1} onChange={e => {setNewPassword1(e.target.value)}}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Confirmer Mot de Passe</label>
                            <input type="password" value={newPassword2} onChange={e => {setNewPassword2(e.target.value)}}/>
                        </Form.Field>
                        <Message error header={errorMsg} />
                        <Message success header={successMsg} />
                        <Button positive size="small" onClick={changePwd} disabled={oldPassword.length === 0 || newPassword1.length === 0 || newPassword2.length === 0}>
                            Enregister
                        </Button>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

var styles = {
    profileContainer: {
        paddingTop: '10vh',
    },
    centerForm: {
        width: '40%',
        marginLeft: '30%',
    }
}