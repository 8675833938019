import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Button, Icon } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';


export default function UserBoard(props){ 

    const [cookies, setCookie, removeCookie] = useCookies(['FC_token']);

    return (
        <React.Fragment>
            <Navbar />
            <div style={styles.container}>
                <h1>Bienvenue sur QUESAQUO</h1>
                {cookies.FC_perm_lvl > 0 ? (
                    <p style={styles.paragraph}>Vous pouvez gérer votre contenu depuis l'onglet Mon Contenu: 
                        <Button icon labelPosition='right' color='teal' size='small' style={{marginLeft: '1em'}} onClick={ e => {window.location.href = "/dashboard"}}>
                            Mon Contenu
                            <Icon name='right arrow' />
                        </Button>
                    </p>
                ) : null}

                <p style={styles.paragraph}>Ici vous pouvez trouver des Quesaquo près de chez vous à l'aide la Carte:   
                    <Button icon labelPosition='right' color='teal' size='small' style={{marginLeft: '1em'}} onClick={ e => {window.location.href = "/explore"}}>
                        Carte
                        <Icon name='right arrow' />
                    </Button>
                </p>
                <p style={styles.paragraph}>Afin de pouvoir acceder au contenu vous devez vous trouver à proximité d'un Quesaquo afin de le flasher via un lecteur de QRCode ou notre application Quesaquo disponible sur le Play Store.</p>
            </div>
        </React.Fragment>
    )
}

var styles = {
    container: {
        padding: '5vh',
        height: '90vh',
    },
    paragraph: {
        fontSize: '1.2em'
    }
}