import React, { useState, useEffect } from 'react';
import { Popup, Icon, Dimmer, Loader, Message, Item,  CardGroup, Card, CardHeader, CardDescription, CardContent, Button } from 'semantic-ui-react'

export default function TableVille(props){

    const [sites, setSites] = useState(null);

    useEffect(() => {
        setSites(props.sites)
    }, [props.sites])

    return (
        <React.Fragment>
            {sites !== null ? (
                <div className="ressouce_table">
                    {sites.length === 0 ? (
                        <Message>
                            <Message.Header>Vous n'avez pas encore de sites.</Message.Header>
                        </Message>
                    ) : (
                        <div style={{ textAlign: 'left'}}>
                            <CardGroup>
                                {sites.map(site => {
                                    return <Card fluid color='#118080' >
                                        <CardContent onClick={e => {props.chooseSite(site)}} style={{cursor: 'pointer'}}>
                                            <CardHeader>{site.nom}</CardHeader>
                                        </CardContent>
                                        <CardContent extra>
                                            <div className='ui one buttons'>
                                                <Button basic color='green' onClick={props.editSite(site)}>
                                                    Modifier
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                })}
                            </CardGroup>
                        </div>
                    )}
                </div>
            ): (
                <Dimmer active inverted>
                    <Loader inverted>Loading Sites</Loader>
                </Dimmer>
            )}
        </React.Fragment>
    )
}

var styles = {
    editIcon: {
        marginLeft: '1em',
    }
}