import React, {useState, useEffect} from 'react';
import { withCookies } from 'react-cookie';
import { Container, Grid, Menu, Image } from 'semantic-ui-react'
import qrcode from '../images/QRCODE_12_PRESENTATION_QUESAQUO.png'

function Home(){

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    useEffect(() => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    },[])

    const login = () => {
        window.location.href = '/login'
    }

    const signup = () => {
        window.location.href = '/signup'
    }

    return (
        <React.Fragment>
            { width > 900 ? (
                <Container fluid style={styles900.mainContainer}>
                    <Menu secondary inverted size='huge'>
                        <Menu.Menu position='right'>
                            <Menu.Item as='a' name='connexion' onClick={login} />
                            <Menu.Item as='a' name='inscription' onClick={signup}/>
                        </Menu.Menu>
                    </Menu>
                    <div style={{textAlign: 'center', marginTop: '8vh'}}>
                        <p style={styles900.h1}>QUESAQUO</p>
                        <p style={styles900.h2}>Decouvrez le monde autrement</p>
                    </div>
                    <Grid columns={2} style={{marginTop: '5vh'}}>
                        <Grid.Row>
                            <Grid.Column style={{textAlign: 'center', padding: '10vh'}}>
                                <div style={{color: 'white'}}>
                                    <h1>Comment ça marche ?</h1>
                                    <h3>Des QRcodes Quesaquo sont disposés dans vos rues, proches de sites remarquables</h3>
                                    <h3>Scanner le Quesaquo pour avoir accès à du contenu exclusif de qualité, fourni par des professionnels du tourisme local</h3>
                                </div>
                            </Grid.Column>
                            <Grid.Column style={{display: 'flex', justifyContent: 'center',padding: '5vh'}}>
                                <Image src={qrcode} size='large' />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            ): null}
            { width < 900 ? (
                <Container fluid style={styles700.mainContainer}>
                    <Menu secondary inverted size='huge'>
                        <Menu.Menu position='right'>
                            <Menu.Item as='a' name='connexion' onClick={login} />
                            <Menu.Item as='a' name='inscription' onClick={signup}/>
                        </Menu.Menu>
                    </Menu>
                    <Grid columns={1} style={{marginTop: '5vh'}}>
                        <Grid.Row style={{textAlign: 'center', marginBottom: '3vh'}}>
                            <Grid.Column>
                                <h1 style={styles700.h1}>QUESAQUO</h1>
                                <h2 style={styles700.h2}>Decouvrez le monde autrement</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{textAlign: 'center', color: 'white', paddingLeft: '5vh', fontSize: '1.3rem', paddingRight: '5vh'}}>
                                <p>Comment ça marche ?</p>
                                <p>Des QRcodes Quesaquo sont disposés dans vos rues, proches de sites remarquables</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{display: 'flex', justifyContent: 'center',padding: '3vh'}}>
                                <Image src={qrcode} size='medium' />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{textAlign: 'center', color: 'white', paddingLeft: '5vh', paddingRight: '5vh', fontSize: '1.3rem'}}>
                                <p>Scannez les Quesaquos pour avoir accès à du contenu exclusif de qualité, fourni par des professionnels du tourisme local</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>

             ) : null}
        </React.Fragment>
    )
}


var styles900 = {
    mainContainer: {
        backgroundColor: '#008080',
        minHeight: '100vh'
    },
    h1: {
        fontSize: '5rem',
        fontWeight: 'bold',
        color:'white',
        margin: 2,
    },
    h2: {
        fontSize: '2rem',
        color:'white'
    }
}

var styles700 = {
    mainContainer: {
        backgroundColor: '#008080',
        minHeight: '100vh',
    },
    h1: {
        fontSize: '3rem',
        fontWeight: 'bold',
        color:'white',
        margin: 2,
    },
    h2: {
        fontSize: '1.5rem',
        color:'white'
    }
}
 
export default withCookies(Home);