import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { List, Table, Tab } from 'semantic-ui-react';
import './Team.css'

class Team extends Component {
    state = { 
        token: this.props.cookies.get('FC_token'),
        perm_level: this.props.cookies.get('FC_perm_lvl'),
        team: null,
     }

    componentDidMount = () => {
        this.loadTeam();
    }

    loadTeam = () => {
        this.setState({villes: null, selectedVille: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/users/get_team/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => {
              console.log(resp)
              this.setState({team: resp.team})
          })
          .catch(error => console.log(error))
    }

    render() { 
        return ( 
            <div className="thirteen wide column board">
                <div className="ui secondary pointing menu">
                    <div className="right menu">
                        <span className="ui item">Logout</span>
                    </div>
                </div>
                <div>
                    {this.state.team === null ? null :(
                        <Table celled collapsing className="team-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Permissions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.team.map(person => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>{person.email}</Table.Cell>
                                            <Table.Cell>{person.permission_level}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    )}
                </div>
            </div>
         );
    }
}
 
export default withCookies(Team);