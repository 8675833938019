import React from 'react';
import Navbar from './Navbar';
import ExploreMap from './maps/ExploreMap'

export default function Explore(props){
    return(
        <div>
            <div style={{
                display: 'flex', 
                justifyContent: "center", 
                alignContent:"center", 
                paddingTop:"1rem", 
                paddingBottom:"1rem", 
                backgroundColor: 'rgb(17,128,128)'
            }}>
                <h1 className="ui header" style={{color: 'white'}}>QUESAQUO</h1>
            </div>
            <div style={styles.mainContainer}>
                <ExploreMap />
            </div>
            <div style={{
                position: 'fixed',  
                bottom: 15, 
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                paddingTop: '1em',
                borderTop: '1px solid rgb(17,128,128)',

            }}>
                <div style={styles.bottomMenuItem} onClick={ e => {window.location.href = "/explore"}}>
                    <i className="map icon big" style={styles.bottomMenuIcon}></i>
                    <p>Carte</p>
                </div>
                <div style={styles.bottomMenuItem}>
                    <i className="language icon big" style={styles.bottomMenuIcon}></i>
                    <p>Langue</p>
                </div>
            </div>
        </div>
    )
}

var styles = {
    mainContainer: {
        width: '90%',
        marginLeft: '5%',
        marginTop: '6vh',
    },
    bottomMenuItem: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomMenuIcon: {
        color: 'rgb(17,128,128)',
    },
}