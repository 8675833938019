import React, { Component } from 'react';
import './App.css';
import Menu from './components/Menu'
import Board from './components/Board'
import Team from './components/team/Team'
import Ressources from './components/ressources/NewRessources'
import { withCookies } from 'react-cookie';

class App extends Component {

  state = {
    token: this.props.cookies.get('mr-token'),
    activeTab: 'ressources',
    ressources : [],
    ressource: null,
    ressourceType: null,
    ressourceLevel: null
  };

  showDashboard = () => {
    this.setState({activeTab: 'board'});
  }
  showRessources = () => {
    this.setState({activeTab: 'ressources'});
  }
  showTeam = () => {
    this.setState({activeTab: 'team'});
  }

  loadRessource = (ressource, ressourceType, ressourceLevel) => {
    this.setState({ressource: ressource, ressourceType: ressourceType, ressourceLevel: ressourceLevel})
  }

  render(){
    return (
      <div className="App ui grid">
        <div className="row">
          <Menu 
            showDashboard={this.showDashboard}
            showRessources={this.showRessources}
            showTeam={this.showTeam}
            loadRessource={this.loadRessource}
          />
          {this.state.activeTab === "ressources" ? <Ressources 
                token={this.state.token} 
                ressource={this.state.ressource} 
                ressourceType={this.state.ressourceType} 
                ressourceLevel={this.state.ressourceLevel}
              /> : null}
          {this.state.activeTab === "board" ? <Board token={this.state.token} /> : null}
          {this.state.activeTab === "team" ? <Team token={this.state.token} /> : null}
        </div>
      </div>
    );
  }
}

export default withCookies(App);
