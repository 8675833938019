import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Icon } from 'semantic-ui-react';
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

export default function FormPointInteret(props){

    const [edit, setEdit] = useState(false)
    const [titre, setTitre] = useState('')
    const [contenu, setContenu] = useState('')
    const [point_interet, setPI] = useState('')
    const [langue, setLangue] = useState('')
    const [published, setPublished] = useState(false)
    const [premium, setPremium] = useState(false)
    const [nbArticles, setNbArticles] = useState(0)

    const plugins = [
        'header',
        'font-bold',
        'font-italic',
        'list-unordered',
        'list-ordered', 
        'link', 
        'logger',
        'full-screen', 
    ];
    const mdParser = new MarkdownIt();


    useEffect(() => {
        setTitre(props.article.titre)
        setContenu(props.article.contenu)
        setPI(props.article.point_interet)
        setLangue(props.article.langue)
        setPremium(props.article.premium)
        setPublished(props.article.published)
        setEdit(props.edit)
        if (props.article.point_interet !== null)
            setNbArticles(computeNbArticles(props.article.point_interet))

    }, [props.article])

    let handleImageUpload = (file, callback)  => {
        const reader = new FileReader()
        reader.onload = () => {      
          const convertBase64UrlToBlob = (urlData) => {  
            let arr = urlData.split(','), mime = arr[0].match(/:(.*?);/)[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n)
            }
            return new Blob([u8arr], {type:mime})
          }
          const blob = convertBase64UrlToBlob(reader.result)
          setTimeout(() => {
            callback('https://avatars0.githubusercontent.com/u/21263805?s=40&v=4')
          }, 1000)
        }
        reader.readAsDataURL(file)
      }

    let handlePublish = () => {
        setPublished(!published)
    }

    let computeNbArticles = (pi) => {
        let point_interet = props.points_interet.filter(point_interet => point_interet.id === parseInt(pi))[0]
        if (point_interet === undefined){
            return 0
        }
        let nbArticles = 0
        point_interet.articles.map(article => {
            if(article.published){
                nbArticles += 1
            }
        })
        return nbArticles
    }

    let handlePiChange = (pi) => {
        setPI(pi)
        let nbArticles = computeNbArticles(pi)
        setNbArticles(nbArticles)
    }

    let canSaveArticle = () => {
        if (edit){
            let pi = props.points_interet.filter(pi => pi.id === parseInt(point_interet))[0]
            if (pi !== undefined){
                let articlesInPi = pi.articles.filter(article => article.published && article.id === props.article.id)
                if (articlesInPi.length > 0){
                    return true
                }
            }
        }
        if (nbArticles >= 4 && published) {
            return false
        }
        return true
    }


    return (
        <React.Fragment>
            <Form style={styles.form}>
                <Form.Field required>
                    <label>Titre</label>
                    <input type="text" name="titre" placeholder="Titre" value={titre} onChange={e => setTitre(e.target.value.toString().toUpperCase())}/>
                </Form.Field>
                <Form.Field required>
                    <label>Contenu</label>
                    <MdEditor
                        value={contenu}
                        plugins={plugins}
                        renderHTML={(text) => mdParser.render(text)}
                        onChange={({html, text}) => setContenu(text)}
                        onImageUpload={handleImageUpload}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Point d'Interet</label>
                    <select className="ui fluid dropdown" onChange={e => handlePiChange(e.target.value)} value={point_interet}>
                        {props.point_interet === null ? (<option value=''>Choisir un Point d'Interet</option>) : null }
                        {props.points_interet.filter(pi => {
                            if (props.point_interet !== null){
                                if(props.point_interet.id === pi.id){
                                    return pi
                                }
                            } else {
                                return pi
                            }
                        }).map(pi => {
                            return (<option key={pi.id} value={pi.id}>{pi.nom}</option>)
                        })}
                    </select>
                </Form.Field>
                <Form.Field required>
                    <label>Langue</label>
                    <select className="ui fluid dropdown" onChange={e => setLangue(e.target.value)} value={langue}>
                        <option value=''>Choisir une Langue</option>
                        {props.langues.map(langue => {
                            return (<option key={langue.id} value={langue.id}>{langue.nom}</option>)
                        })}
                    </select>
                </Form.Field>
                <Form.Field>
                    <div className="ui toggle checkbox" onClick={() => handlePublish()} checked={published}>
                        <input name="published" type="checkbox" checked={published}/>
                        <label>Visible</label>
                    </div>
                </Form.Field>
                <Button.Group>
                    {edit ? (
                        <React.Fragment>
                            <Button negative onClick={props.deleteArticle}>Supprimer Article</Button>
                            <Button.Or />
                        </React.Fragment>
                    ) : null}
                    <Button onClick={props.cancelForm}>Annuler</Button>
                    <Button.Or />
                    <Button positive icon labelPosition='right' loading={props.loading}
                        disabled={titre === '' || contenu === '' || point_interet === '' || langue === '' || !canSaveArticle()}
                        onClick={props.saveArticle({titre: titre, contenu: contenu,
                                                     point_interet: point_interet,
                                                     langue: langue, premium: premium, published: published})}>
                            <Icon name="check" />
                            Enregistrer
                    </Button>
                </Button.Group>
                { props.errors ? (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>{props.errors}</Message.Header>
                    </Message>
                ): null}  
                { canSaveArticle() ? null : (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>Le point d'interet choisi contient deja {nbArticles} articles visibles.</Message.Header>
                        <Message.Content>Veuillez desactiver ou supprimer un article de ce point d'interet</Message.Content>
                    </Message>
                )}
            </Form>
        </React.Fragment>
    )
}


var styles = {
    form: {
        width: '70%',
        marginLeft: '15%',
        textAlign: 'left',
        marginTop: '2vh',
    }
}