import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import { withCookies } from 'react-cookie';
import { confirmAlert } from 'react-confirm-alert';
import TableMonument from './TableMonument';
import FormMonument from './FormMonument';
import Searcher from '../Searcher';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Monument extends Component {
    state = { 
        token: this.props.cookies.get('FC_token'),
        perm_level: this.props.cookies.get('FC_perm_lvl'),
        requiredPermLevel: 3,
        monuments: null,
        sites: [],
        selectedMonument: null,
        errors: null,
        edit: false,
    }

    componentDidMount(){
        this.loadMonuments();
        this.loadSites();
    }

    loadSites = () => {
        this.setState({sites: [], selectedSite: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/sites/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({sites: resp}))
          .catch(error => console.log(error))
    }

    loadMonuments = () => {
        this.setState({monuments: null, selectedMonument: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/monuments/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({monuments: resp}))
          .catch(error => console.log(error))
    }

    setMonuments = (monuments) => {
        this.setState({monuments: monuments})
    }

    newMonument = () => {
        if (this.props.choosenSite !== null){
            this.setState({edit: false, selectedMonument: {nom:'', site: this.props.choosenSite.id}})
        } else {
            this.setState({edit: false, selectedMonument: {nom:'', site: ''}})
        }
    }

    saveMonument = monument => event => {
        event.preventDefault();

        let sm = this.state.selectedMonument
        sm.nom = monument.nom
        sm.site = monument.site
        this.setState({selectedMonument: sm})

        let method = this.state.edit ? "PUT" : "POST"
        let url = this.state.edit ? `${process.env.REACT_APP_API_URL}/api/monuments/${this.state.selectedMonument.id}/` :
        `${process.env.REACT_APP_API_URL}/api/monuments/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedMonument)
        }).then(response => response.json() )
        .then(resp => {
            if(Array.isArray(resp.non_field_errors)){
                this.setState({errors: resp.non_field_errors})
            } else {
                if(this.state.edit){
                    this.loadMonuments();
                } else {
                    this.setState({monuments: [...this.state.monuments, resp], selectedMonument: null, edit: false});
                    this.cancelForm();
                }
            }
        })
        .catch(error => console.log(error))
    }

    cancelForm = (event) => {
        event.preventDefault();
        this.setState({selectedMonument: null, edit: false});
        this.cleanErrors();
        this.loadMonuments();
    }

    editMonument = monument => event => {
        event.preventDefault();
        this.setState({selectedMonument: monument, edit:true})
    }

    confirmDelete = event => {
        event.preventDefault();
        confirmAlert({
            title: "Confirmer supression",
            message: `Etes vous sur de vouloir supprimer le monument ${this.state.selectedMonument.nom} ?`,
            buttons: [
                {label: "Oui", onClick: () => this.deleteMonument(event)},
                {label: "Non", onClick: () => this.cancelForm(event)},
            ]
        });

    }

    deleteMonument = event => {
        event.preventDefault();
        let method = "DELETE"
        let url = `${process.env.REACT_APP_API_URL}/api/monuments/${this.state.selectedMonument.id}/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedMonument)
        }).then(resp => {
            if(resp.ok){
                const monuments = this.state.monuments.filter(monument => monument.id !== this.state.selectedMonument.id);
                this.setState({monuments: monuments, edit: false, selectedMonument: null})
            } else {
                this.setState({errors: "Vous ne pouvez pas supprimer ce Monument car il contient encore au moins un Point d'Interet."})
            }
        })
        .catch(error => console.log(error))
    }

    cleanErrors = () => {
        this.setState({errors: null})
    }

    getNomSite = (monument) => {
        let sites = this.state.sites.filter(site => site.id === monument.site)
        if (sites.length > 0) {
            return sites[0].nom
        } else {
            return ""
        }
    }

    chooseMonument = monument => {
        this.props.loadSections(monument, 'monuments', 3);
    }

    getMonuments = (s) => {
        if(this.state.monuments !== null){
            let monuments = this.state.monuments.filter(monument => {
                if(s !== null){
                    if(monument.site === s.id){
                        return monument
                    }                                            
                } else {
                    return monument
                }
            })
            return monuments
        }
        return null
    }

    render() { 
        const s = this.props.choosenSite
        const monuments = this.getMonuments(s);
        return ( 
            <div>
                <div style={{textAlign: 'left'}}>
                    {this.state.perm_level >= this.state.requiredPermLevel && this.state.selectedMonument !== null ? ( 
                        <FormMonument 
                            monument={this.state.selectedMonument}
                            site={this.props.choosenSite}
                            sites={this.state.sites}
                            deleteMonument={this.confirmDelete}
                            cancelForm={this.cancelForm} 
                            edit={this.state.edit}
                            saveMonument={this.saveMonument}
                            errors={this.state.errors}
                            cleanErrors={this.cleanErrors}
                        />
                        
                    ) : (
                        <div>
                            {this.state.perm_level >= this.state.requiredPermLevel  ? (
                                <div>
                                    <div style={{margin: '4rem'}}>
                                        <Button size='tiny' positive onClick={this.newMonument}>CREER MONUMENT</Button>
                                    </div>
                                    {/* <Searcher 
                                        items={this.state.monuments} 
                                        setItems={this.setMonuments}
                                        resetSearch={this.loadMonuments}
                                    /> */}
                                    <TableMonument
                                        monuments={monuments}
                                        editMonument={this.editMonument}
                                        chooseMonument={this.chooseMonument}
                                        getNomSite={this.getNomSite}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )}

                </div>
            </div>
         );
    }
}
 
export default withCookies(Monument);