import React, { Component } from 'react';
import { Button, Card, Image, Icon, Step, Popup } from 'semantic-ui-react'
import { withCookies } from 'react-cookie';
import { confirmAlert } from 'react-confirm-alert';
import TablePointInteret from './TablePointInteret';
import FormPointInteret from './FormPointInteret';
import PiMap from '../../maps/PiMap';
import Searcher from '../Searcher';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';


class Monument extends Component {
    state = { 
        token: this.props.cookies.get('FC_token'),
        perm_level: this.props.cookies.get('FC_perm_lvl'),
        requiredPermLevel: 2,
        points_interet: null,
        monuments: [],
        selectedPointInteret: null,
        errors: null,
        edit: false,
        showQrCode: false,
        step: 'text',
        mapMsg: null,
        mapError: null,
        flash_data: []
    }

    componentDidMount(){
        this.loadPointsInteret();
        this.loadMonuments();
    }

    loadMonuments = () => {
        this.setState({monuments: [], selectedMonument: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/monuments/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({monuments: resp}))
          .catch(error => console.log(error))
    }

    loadPointsInteret = () => {
        this.setState({points_interet: null, selectedPointInteret: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/points_interet/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({points_interet: resp}))
          .catch(error => console.log(error))
    }

    setPointsInteret = (pis) => {
        this.setState({points_interet: pis})
    }

    savePointInteret = pi => event => {
        event.preventDefault();

        let si = this.state.selectedPointInteret
        si.nom = pi.nom
        si.monument = pi.monument
        si.active = pi.active
        this.setState({selectedPointInteret: si})

        let method = this.state.edit ? "PUT" : "POST"
        let url = this.state.edit ? `${process.env.REACT_APP_API_URL}/api/points_interet/${this.state.selectedPointInteret.id}/` :
                                    `${process.env.REACT_APP_API_URL}/api/points_interet/`
        
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(si)
        }).then(response => response.json() )
        .then(resp => {
            if(Array.isArray(resp.non_field_errors)){
                this.setState({errors: resp.non_field_errors})
            } else {
                if(this.state.edit){
                    this.loadPointsInteret();
                } else {
                    this.setState({points_interet: [...this.state.points_interet, resp], selectedPointInteret: null, edit: false});
                }
                this.cancelForm();
            }
        })
        .catch(error => console.log(error))
    }

    cancelForm = (event) => {
        event.preventDefault();
        this.setState({selectedPointInteret: null, edit: false})
        this.cleanErrors();
    }

    editPointInteret = (pi, step) => event => {
        event.preventDefault();
        this.setState({selectedPointInteret: pi, edit:true, step: step})
        this.computeFlashData(pi);
    }

    confirmDelete = event => {
        event.preventDefault();
        confirmAlert({
            title: "Confirmer supression",
            message: `Etes vous sur de vouloir supprimer le point d'interet ${this.state.selectedPointInteret.nom} ?`,
            buttons: [
                {label: "Oui", onClick: () => this.deletePointInteret(event)},
                {label: "Non", onClick: () => this.cancelForm(event)},
            ]
        });
    }

    newPointInteret = () => {
        if (this.props.choosenMonument !== null){
            this.setState({edit: false, selectedPointInteret: {nom:'', monument: this.props.choosenMonument.id, active: false}})
        } else {
            this.setState({edit: false, selectedPointInteret: {nom:'', monument: '',  active: false}})
        }
    }

    deletePointInteret = event => {
        event.preventDefault();
        let method = "DELETE"
        let url = `${process.env.REACT_APP_API_URL}/api/points_interet/${this.state.selectedPointInteret.id}/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedPointInteret)
        }).then(resp => {
            if(resp.ok){
                const points_interet = this.state.points_interet.filter(pi => pi.id !== this.state.selectedPointInteret.id);
                this.setState({points_interet: points_interet, edit: false, selectedPointInteret: null})
            } else {
                this.setState({errors: "Vous ne pouvez pas supprimer ce Point d'Interet car il contient encore au moins un Article."})
            }
        })
        .catch(error => console.log(error))
    }

    cleanErrors = () => {
        this.setState({errors: null})
    }

    getNomMonument = (pi) => {
        let monuments = this.state.monuments.filter(monument => monument.id === pi.monument)
        if (monuments.length > 0) {
            return monuments[0].nom
        } else {
            return ""
        }
    }

    choosePointInteret = pi => {
        this.props.loadSections(pi, 'points_interet', 2);
    }

    strToDate = (dateStr) => {
        let time = dateStr.timeRef.split('-');
        let month = parseInt(time[0]);
        let year = parseInt(time[1]);
        let date = new Date(year, month-1, 1);
        return date;
    }

    computeFlashData = (pi) => {
        if(pi.flashed_at.length > 0){
            let data = {}
            pi.flashed_at.forEach(flash => {
                let date = Date.parse(flash.flashed_at)
                date = new Date(date);
                let month = (date.getMonth() + 1).toString()
                let timeRef = month + '-' + date.getFullYear()
                if (timeRef in data){
                    data[timeRef]['flashed'] += 1
                } else {
                    data[timeRef] = {
                        'timeRef': timeRef.toString(),
                        'flashed': 1
                    }
                }
            })
            let flash_data = Object.entries(data).map(entry => {
                return entry[1]
            })

            flash_data.sort((a, b) => {
                let dateA = this.strToDate(a);
                let dateB = this.strToDate(b);
                if (dateA < dateB) {
                    return - 1;
                } else if (dateA > dateB) {
                    return 1;
                }
                return 0;
            })

            this.setState({'flash_data': flash_data})
        }
    }

    getPointsInteret = (m) => {
        if(this.state.points_interet !== null){
            let points_interet = this.state.points_interet.filter(pi => {
                if(m !== null){
                    if(pi.monument === m.id){
                        return pi
                    }                                            
                } else {
                    return pi
                }
            })
            return points_interet
        }
        return null
    }

    downloadQR = () => {
        var win = window.open(this.state.selectedPointInteret.qrcode, '_blank');
        win.focus();
    }

    toText = () => {
        this.setState({step: "text"})
    }

    toCode = () => {
        this.setState({step: "qrcode"})
    }

    toMap = () => {
        this.setState({step: "map"})
    }

    toStats = () => {
        this.computeFlashData(this.state.selectedPointInteret);
        this.setState({step: "stats"});
    }

    savePiPosition = (lat, lng) => {
        let pid = this.state.selectedPointInteret.id
        fetch(`${process.env.REACT_APP_API_URL}/api/points_interet/${pid}/mark_position/`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.state.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({lat: lat, lng: lng})
        }).then(response => response.json())
          .then(resp => {
              if('message' in resp){
                let pi = this.state.selectedPointInteret
                pi.lat = lat
                pi.lng = lng
                this.setState({mapMsg: resp.message, selectedPointInteret: pi})
              }
              if('error' in resp){
                this.setState({mapError: resp.error})
              }
          })
          .catch(error => console.log(error))
    }

    deletePiPosition = () => {
        let pid = this.state.selectedPointInteret.id
        let url = `${process.env.REACT_APP_API_URL}/api/points_interet/${pid}/delete_position/`
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.state.token}`,
                'Content-Type': 'application/json',
            },
        }).then(response => response.json())
          .then(resp => {
              if('message' in resp){
                let pi = this.state.selectedPointInteret
                pi.lat = null
                pi.lng = null
                this.setState({mapMsg: resp.message, selectedPointInteret: pi})
              }
              if('error' in resp){
                this.setState({mapError: resp.error})
              }
          })
          .catch(error => console.log(error))
    }

    removeMapMsg = () => {
        this.setState({mapMsg: null})
    }

    removeMapError = () => {
        this.setState({mapError: null})
    }

    render() { 
        const m = this.props.choosenMonument
        const points_interet = this.getPointsInteret(m)

        return ( 
            <div>
                <div style={{textAlign: 'left'}}> 
                    {this.state.perm_level >= this.state.requiredPermLevel && this.state.selectedPointInteret !== null ? ( 
                        <div style={{textAlign: 'center', marginTop: '5vh'}}>
                            <Step.Group>
                                <Step active={true} onClick={this.toText}
                                    style={{cursor: 'pointer'}}>
                                    <Step.Content  style={{cursor: 'pointer'}}>
                                        <Step.Title>Creez le Point d'Interet</Step.Title>
                                    </Step.Content>
                                </Step>
                                {this.state.selectedPointInteret.nom !== '' ? (
                                <React.Fragment>
                                    <Step active={this.state.step === "qrcode"} 
                                        onClick={this.toCode} style={{cursor: 'pointer'}}>
                                        <Step.Content>
                                            <Step.Title>Visualiser le Qrcode</Step.Title>
                                        </Step.Content>
                                    </Step>
                                    <Step active={this.state.step === "map"}
                                        onClick={this.toMap} style={{cursor: 'pointer'}}>
                                        <Step.Content>
                                            <Step.Title>Localiser sur la carte</Step.Title>
                                        </Step.Content>
                                    </Step>
                                    <Step active={this.state.step === "stats"}
                                        onClick={this.toStats} style={{cursor: 'pointer'}}>
                                        <Step.Content>
                                            <Step.Title>Statistiques</Step.Title>
                                        </Step.Content>
                                    </Step>
                                </React.Fragment> ) : null}
                            </Step.Group>

                            {this.state.step === 'text' ? (
                                <FormPointInteret 
                                    point_interet={this.state.selectedPointInteret}
                                    monument={this.props.choosenMonument}
                                    monuments={this.state.monuments}
                                    points_interet={this.state.points_interet}
                                    perm_level={this.state.perm_level}
                                    deletePointInteret={this.confirmDelete}
                                    cancelForm={this.cancelForm} 
                                    edit={this.state.edit}
                                    savePointInteret={this.savePointInteret}
                                    errors={this.state.errors}
                                    cleanErrors={this.cleanErrors}
                                />
                            ) : null }

                            {this.state.step === 'qrcode' && this.state.selectedPointInteret !== null && this.state.selectedPointInteret.qrcode !== undefined? (
                                <div className='qrcode'>
                                    <div style={styles.closeDiv}>
                                        <Popup
                                            trigger={<Icon name='close' link size="large" onClick={this.cancelForm} />}> 
                                            Fermer
                                        </Popup>
                                    </div>
                                    <Card centered>
                                        <Image src={this.state.selectedPointInteret.qrcode}/>
                                        <Card.Content>
                                            QRCODE - {this.state.selectedPointInteret.nom}
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button primary size="small" onClick={this.downloadQR}>
                                                <Icon name="download"/>
                                                Telecharger
                                            </Button>
                                        </Card.Content>
                                    </Card>
                                </div>
                            ) : null}

                            {this.state.step === 'map' ? (
                                <PiMap 
                                    point_interet={this.state.selectedPointInteret}
                                    savePiPosition={this.savePiPosition}
                                    deletePiPosition={this.deletePiPosition}
                                    msg={this.state.mapMsg}
                                    error={this.state.mapError}
                                    removeMapMsg={this.removeMapMsg}
                                    removeMapError={this.removeMapError}
                                    cancelForm={this.cancelForm} 
                                 />
                            ) : null}

                            {this.state.step === 'stats' ? (
                                <div style={{marginTop: '10vh'}}>
                                    <div style={styles.closeDiv}>
                                        <Popup
                                            trigger={<Icon name='close' link size="large" onClick={this.cancelForm} />}> 
                                            Fermer
                                        </Popup>
                                    </div>
                                    <h1>Statistiques</h1>
                                    <p>Flashé {this.state.selectedPointInteret.flashed_at.length} fois</p>
                                    {this.state.flash_data.length !== 0 ? 
                                        <div style={styles.chartContainer}>
                                            <LineChart width={400} height={400} data={this.state.flash_data}>
                                                <Line type="monotone" dataKey="flashed" stroke="#8884d8" />
                                                <CartesianGrid stroke="#ccc" />
                                                <XAxis dataKey="timeRef" />
                                                <YAxis />
                                                <Tooltip />
                                            </LineChart>
                                        </div> 
                                    : null}
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            {this.state.perm_level >= this.state.requiredPermLevel ? (
                                <div>
                                    <div style={{margin: '4rem'}}>
                                        <Button positive size='tiny' onClick={this.newPointInteret}>CREER POINT D'INTERET</Button>
                                    </div>
                                    {/* <Searcher 
                                        items={this.state.points_interet} 
                                        setItems={this.setPointsInteret}
                                        resetSearch={this.loadPointsInteret}
                                    /> */}
                                    <TablePointInteret
                                        points_interet={points_interet}
                                        editPointInteret={this.editPointInteret}
                                        choosePointInteret={this.choosePointInteret}
                                        getNomMonument={this.getNomMonument}
                                    />
                                </div>
                                
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
         );
    }
}

var styles = {
    closeDiv: {
        width: '50%',
        marginLeft: '25%',
        display: "flex",
        justifyContent: 'flex-end'
    },
    chartContainer: {
        width: '50%',
        marginLeft: '25%',
        display: "flex",
    }
}
 
export default withCookies(Monument);