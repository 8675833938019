import React, { useState, useEffect } from 'react';
import { Popup, Icon, Dimmer, Loader, Message, Item, CardGroup, Card, CardHeader, CardDescription, CardContent, Button } from 'semantic-ui-react'

export default function TableVille(props){

    const [villes, setVilles] = useState([]);

    useEffect(() => {
        setVilles(props.villes);
    }, [props.villes])

    return (
        <React.Fragment>
            {villes !== null ? (
                <div className="ressouce_table">
                    {villes.length === 0 ? (
                        <Message>
                            <Message.Header>Vous n'avez pas encore de villes.</Message.Header>
                        </Message>
                    ) : (
                        <div style={{ textAlign: 'left'}}>
                            <CardGroup>
                                {villes.map(ville => {
                                    return <Card fluid color='#118080' >
                                        <CardContent onClick={e => {props.chooseVille(ville)}} style={{cursor: 'pointer'}}>
                                            <CardHeader>{ville.nom}</CardHeader>
                                            <CardDescription>
                                                <p>Code Postal: {ville.code_postal}</p>
                                                <p>Pays: {ville.pays}</p>
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent extra>
                                            <div className='ui one buttons'>
                                                <Button basic color='green' onClick={props.editVille(ville)}>
                                                    Modifier
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                })}
                            </CardGroup>
                        </div>
                    )}
                </div>
            ): (
                <Dimmer active inverted>
                    <Loader inverted>Loading Villes</Loader>
                </Dimmer>
            )}
        </React.Fragment>
    )
}

var styles = {
    editIcon: {
        marginLeft: '1em',
    }
}