import React, {useState, useEffect} from 'react';
import { Button, Icon, Form, Input, Progress, Item} from 'semantic-ui-react';
import ReactAudioPlayer from 'react-audio-player';


export default function AudioArticle(props){
    

    const [audios, setAudios] = useState([])
    const [article, setArticle] = useState(null)
    const [nbAudios, setNbAudios] = useState(0)

    useEffect(() => {
        setArticle(props.article)
        setAudios(props.article.audios)
        setNbAudios(props.article.audios.length)
    },[props.article])

    const prev = (e) => {
        props.prev(e)
    }

    const next = (e) => {
        props.next(e, article.audios)
    }

    const handleAudio = e => {
        e.preventDefault();
        let audio = Array.from(e.target.files).pop()
        props.handleAudio(audio)
    }

    const handleTitre = (audio , evt) => {
        audio.titre = evt.target.value
        let audios = article.audios
        let index = audios.findIndex(x => x.id === audio.id)
        audios[index] = audio
        setArticle({...article, [audios]: audios})
    }

    return(
        <React.Fragment>
            {props.article !== null ? (
                <div style={styles.audioPlayer}>
                    {props.article.audios.map(audio => {
                        return (
                            <Item key={audio.id}>
                                <Item.Content>
                                    <Item.Description>
                                        <Form>
                                            <Form.Group>
                                                <Form.Field>
                                                    <ReactAudioPlayer src={audio.url} controls controlsList="nodownload"/>
                                                </Form.Field>
                                                <Form.Field>
                                                    <input type="text" placeholder="Titre" value={audio.titre} onChange={e => handleTitre(audio, e)}/>
                                                </Form.Field>
                                                <Form.Field>
                                                    <Icon name="trash" style={styles.trash} onClick={e => {props.deleteAudio(audio, e)}}/>
                                                </Form.Field>
                                            </Form.Group>
                                        </Form>
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                        )
                    })}
                </div>
            ) : null}
            <Form style={styles.form}>
                <Form.Field>
                    <Input type="file" id="audio" accept="audio/mp3" onChange={e => handleAudio(e)} loading={props.loading} disabled={props.loading} />
                    {props.loading ? <Progress percent={props.uploadPercent} progress indicating/> : null }
                </Form.Field>
                <Form.Field>
                    <Button.Group>
                        <Button icon labelPosition='left' onClick={e => prev(e)} disabled={props.loading}>
                            <Icon name="left arrow" />
                            Precedent
                        </Button>
                        <Button.Or />
                        <Button icon positive labelPosition='right' onClick={ e => next(e)} disabled={props.loading}>
                            <Icon name="check" />
                            Enregister
                        </Button>
                    </Button.Group>
                </Form.Field>
            </Form>
        </React.Fragment>
    )
}

var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    itemgroup: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    audioPlayer:{
        width: '60%',
        marginLeft: '20%',
        marginBottom: '5vh',
        marginTop: '2vh',

    },
    trash: {
        color: 'red',
        cursor: 'pointer',
    }
}