import React, {useState, useEffect} from 'react';
import { withCookies } from 'react-cookie';
import { Icon } from 'semantic-ui-react';

function MyMenu (props) {
    const [ressources, setRessources] = useState([])
    const [ressourceType, setRessourceType] = useState(null)
    const [ressourceLevel, setRessourceLevel] = useState(null)

    useEffect(() => {
        fetchRessources()
    }, [])

    const fetchRessources = () => {
        setRessources([])
        
        let permLevel = parseInt(props.cookies.get('FC_perm_lvl'))
        let ressource_name = ''
        switch(permLevel){
            case 6:
                ressource_name = 'territoires'
                break;
            case 5:
                ressource_name = 'villes'
                break;
            case 4:
                ressource_name = 'sites'
                break;
            case 3:
                ressource_name = 'monuments'
                break;
            case 2:
                ressource_name = 'points_interet'
                break;
            case 1:
                ressource_name = 'articles'
                break;
        }
        setRessourceType(ressource_name)
        setRessourceLevel(permLevel)
        fetch(`${process.env.REACT_APP_API_URL}/api/${ressource_name}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${props.cookies.get('FC_token')}`,
            }
        }).then(response => response.json()).then(resp => {
            setRessources(resp)
        }).catch(error => {
            console.log(error)
            setRessources([])
        })

    }

    return ( 
        <div className="three wide column" style={styles.menuContainer}>
            <h1 className="app-logo" style={styles.logo}><a href="/home">QUESAQUO</a></h1>
            <div class="ui divider"></div>
            <div>
                <h2>Mon contenu</h2>
                {ressources.map(resssource => {
                    return (<p style={styles.menuItem} onClick={() => props.loadRessource(resssource, ressourceType, ressourceLevel)}>{resssource.nom}</p>)
                })}
            </div>
            <div class="ui divider"></div>

            <div class="ui" style={{color: 'black', marginTop: '1vh'}}>
                <h3><a href="/explore" style={{color: 'black', }}>La carte</a></h3>
            </div>


            {/* <div className="ui secondary inverted vertical menu" style={styles.menu}> */}
                {/* <a className="item" onClick={props.showDashboard}>Tableau de Bord</a> */}
                {/* <a className="item" onClick={props.showRessources}>Ressources</a> */}
                {/* <a className="item" onClick={props.showTeam}>Equipes</a> */}
            {/* </div> */}
        </div>
    );
}

export default withCookies(MyMenu);

var styles = {
    menuContainer:{
        padding: '2em',
        backgroundColor: '#118080',
    },
    logo: {
        color: '#008080',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    menu: {
        width: '100%',
    },
    menuItem: {
        fontSize: '1em',
        cursor: 'pointer',
    }
}