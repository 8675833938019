import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Content, Popup, Icon } from 'semantic-ui-react';


export default function FormPointInteret(props){

    const [edit, setEdit] = useState(false)
    const [nom, setNom] = useState('')
    const [active, setActive] = useState(false)
    const [monument, setMonument] = useState('')
    const [piLimit, setPiLimit] = useState(0)
    const [activePis, setActivePis] = useState(0)


    useEffect(() => {
        setNom(props.point_interet.nom)
        setActive(props.point_interet.active)
        handleMonumentChange(props.point_interet.monument)
        setEdit(props.edit)
        computeActivePis()
    }, [props.point_interet])

    
    const computeActivePis = () => {
        let activePis = 0
        props.points_interet.map(pi => {
            if (pi.active){
                activePis += 1
            }
        })
        setActivePis(activePis)
    }

    const handleMonumentChange = (monumentId) => {
        let monuments = props.monuments.filter(monument => monument.id == monumentId)
        if (monuments.length > 0){
            setPiLimit(monuments[0].pi_limit)
        }
        setMonument(monumentId)
    }

    const handleActiveChange = () => {
        setActive(!active)
        if (!active){
            setActivePis(activePis + 1)
        } else {
            setActivePis(activePis - 1)
        }
    }

    return (
        <div style={{marginTop: '10vh'}}>
            <div style={styles.closeDiv}>
                <Popup
                    trigger={<Icon name='close' link size="large" onClick={props.cancelForm} />}> 
                    Fermer
                </Popup>
            </div>
            <Form style={styles.form}>
                <Form.Field required>
                    <label>Nom</label>
                    <input type="text" name="nom" placeholder="Nom" value={nom} onChange={e => setNom(e.target.value.toString().toUpperCase())}/>
                </Form.Field>
                <Form.Field required>
                    <label>Monument</label>
                    <select className="ui fluid dropdown" onChange={e => handleMonumentChange(e.target.value)} value={monument}>
                        {props.monument === null ? (<option value=''>Choisir un Monument</option>) : null }
                        {props.monuments.filter(monument => {
                            if (props.monument !== null){
                                if(props.monument.id === monument.id){
                                    return monument
                                }
                            } else {
                                return monument
                            }
                        }).map(monument => {
                            return (<option key={monument.id} value={monument.id}>{monument.nom}</option>)
                        })}
                    </select>
                </Form.Field>
                    <Form.Field>
                        <div className="ui toggle checkbox" onClick={handleActiveChange} checked={active}>
                            <input name="active" type="checkbox" checked={active}/>
                            <label>Actif</label>
                        </div>
                    </Form.Field>
                <Button.Group>
                    <Button positive 
                        disabled={nom === '' || monument === '' || activePis > piLimit}
                        onClick={props.savePointInteret({nom: nom, monument: monument, active: active})}>Valider</Button>
                    {edit ? (
                        <React.Fragment>
                            <Button.Or />
                            <Button negative onClick={props.deletePointInteret}>Supprimer le Point d'Interet</Button>
                        </React.Fragment>
                    ) : null}
                </Button.Group>
                { props.errors ? (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>{props.errors}</Message.Header>
                    </Message>
                ): null}  
                { piLimit > 0 && activePis > piLimit ? (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>Vous avez deja {activePis} points d'interets actifs sur un maximum de {piLimit}.</Message.Header>
                        <Message.Content>Veuillez desactiver ou supprimer un Point d'interet</Message.Content>
                    </Message>
                ) : null}
            </Form>
        </div>
    )
}



var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    closeDiv: {
        width: '50%',
        marginLeft: '25%',
        display: "flex",
        justifyContent: 'flex-end'
    },
}