import React, {useEffect, useState} from 'react';
import Leaflet from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Button } from 'semantic-ui-react';

Leaflet.Icon.Default.imagePath =
'../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default function PiMap(props){

    const [lat, setLat] = useState(43.257972)
    const [lng, setLng] = useState(1.202683)
    const [zoom, setZoom] = useState(13)
    const [piLat, setPiLat] = useState(null)
    const [piLng, setPiLng] = useState(null)

    const handleClick = (e) => {
        const { lat, lng } = e.latlng;
        setPiLat(lat)
        setPiLng(lng)
    }

    useEffect(() => {
        setPiLat(props.point_interet.lat)
        setPiLng(props.point_interet.lng)
    }, [props.point_interet])

    const deletePiPosition = () => {
        setPiLat(null);
        setPiLng(null);
        props.deletePiPosition();
    }

    return (
        <div style={{paddingTop: '5vh'}}>
            <Map 
                center={[lat, lng]} 
                zoom={zoom}
                doubleClickZoom={false}
                onclick={handleClick}
            >
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {piLat !== null && piLng !== null ? (
                    <Marker position={[piLat, piLng]}>
                        <Popup>
                            {props.point_interet.nom}
                        </Popup>
                    </Marker>
                ) : null}
            </Map>
                <div>
                {piLat !== null && piLng !== null ? ( <Button positive style={{marginTop: '5rem'}} onClick={() => {props.savePiPosition(piLat, piLng)}}>Valider Position</Button> ) : null }
                {piLat !== null && piLng !== null ? ( <Button negative style={{marginTop: '5rem'}} onClick={deletePiPosition}>Supprimer Position</Button> ) : null }
                    <Button style={{marginTop: '5rem'}} onClick={(e) => {props.cancelForm(e)}} >Fermer la carte</Button>
                </div>
            {props.msg !== null ? (
                <div className="ui positive message" style={{width: '50%', marginLeft: '25%'}}>
                    <i aria-hidden="true" className="close icon" onClick={props.removeMapMsg}></i>
                    <div className="header">{props.msg}</div>
                </div>
            ) : null}
            {props.error !== null ? (
                <div className="ui negative message" style={{width: '50%', marginLeft: '25%'}}>  
                    <i aria-hidden="true" className="close icon" onClick={props.removeMapError}></i>
                    <div className="header">{props.error}</div>
                </div>
            ) : null}
        </div>
    )

}
