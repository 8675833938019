import React, { useState, useEffect } from 'react';
import { Accordion, Button, Icon, Form, Progress, Card, Item, Input } from 'semantic-ui-react';

export default function ImagesArticles(props){
    const [images, setImages] = useState([])
    const [article, setArticle] = useState(null)
    const [nbImages, setNbImages] = useState(0)
    const [activeIndex, setActiveIndex] = useState(-1)

    useEffect(() => {
        setArticle(props.article)
        setImages(props.article.images)
        setNbImages(props.article.images.length)
    },[props.article])

    const handleImages = event => {
        props.handleImage(event, nbImages+1)
        event.target.value = null;
    }

    const handleLegend = (image , evt) => {
        image.legende = evt.target.value
        let imgs = article.images
        let index = imgs.findIndex(x => x.id === image.id)
        imgs[index] = image
        setArticle({...article, [images]: imgs})
    }

    const handleNumberChanger = (image ,evt) => {
        evt.preventDefault();
        image.position = parseInt(evt.target.value)
        let imgs = article.images
        let index = imgs.findIndex(x => x.id === image.id)
        imgs[index] = image
        setArticle({...article, [images]: imgs})
    }

    const prev = e => {
        props.prev(e)
    }

    const next = e => {
        article.images.map(img => {
            props.saveLegend(img, article)
            props.savePosition(img, article)
        })
        props.next(e)
    }

    const handleAccordionClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }

    return (
        <React.Fragment>
            <Item.Group style={styles.itemgroup} divided>
                {images.map((image, index) => {
                    return (
                        <Item key={image.id}>
                            <Item.Image src={image.url} size='tiny' style={styles.image}/>
                            <Item.Content>
                                <Item.Description>
                                    <Form>
                                        <Form.Group>
                                            <Form.Field width={10}>
                                                <input type="text" placeholder="Legende" value={image.legende} onChange={e => handleLegend(image, e)}/>
                                            </Form.Field>
                                            <Form.Field width={3}>
                                                <input type="number" placeholder="N°" min={0} value={image.position} onChange={e => handleNumberChanger(image, e)}/>
                                            </Form.Field>
                                            <Form.Field>
                                               <Icon name="trash" style={styles.trash} onClick={e => {props.deleteImage(image, e)}}/>
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Item.Description>
                                <Item.Extra>
                                    <Accordion>
                                        <Accordion.Title
                                            active={activeIndex === index}
                                            index={index}
                                            onClick={handleAccordionClick}
                                        >
                                            <Icon name='dropdown' />
                                            Voir les traductions
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === index}>
                                            {image.traductions_legendes.map(trad => {
                                                return (
                                                    <p>{trad.langue.nom} : {trad.traduction}</p>
                                                )
                                            })}
                                        </Accordion.Content>
                                    </Accordion>
                                </Item.Extra>
                            </Item.Content>
                        </Item>
                    )
                })}
            </Item.Group>
            <Form style={styles.form}> 
                <Form.Field>
                    <Input type="file" id="image" accept="image/png, image/jpeg" multiple onChange={e => handleImages(e)} 
                            loading={props.loading} disabled={props.loading}/>
                     {props.loading ? <Progress percent={props.uploadPercent} progress indicating/> : null }
                </Form.Field>
                <Form.Field>
                    <Button.Group>
                        <Button icon labelPosition='left' onClick={e => prev(e)} disabled={props.loading}>
                            <Icon name="left arrow" />
                            Precedent
                        </Button>
                        <Button.Or />
                        <Button icon positive labelPosition='right' onClick={ e => next(e)} disabled={props.loading}>
                            <Icon name="check" />
                            Enregistrer
                        </Button>
                    </Button.Group>
                </Form.Field>
            </Form>
        </React.Fragment>
    )
}

var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    itemgroup: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    trash:{
        color: 'red',
        cursor: 'pointer'
    },
    image:{
        imageOrientation: 'from-image',
    }
}