import React, { Component } from 'react';
import { withCookies } from 'react-cookie';


class ResetPasswd extends Component {
    state = {
        email: '',
        errorMsg: '',
        resetMsg: '',
        width: null,
    }


    componentDidMount = () => {
        this.setState({width: window.innerWidth})
    }

    inputChanged = (event) => {
        this.setState({email: event.target.value})
    }

    resetPasswd = (e) => {
        e.preventDefault();
        if(this.state.email === ''){
            this.setState({errorMsg: 'Veuillez renseigner votre adresse email'})
        } else {
            let host = process.env.REACT_APP_API_URL
            let url = `${host}/api/password_reset/`
            fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: this.state.email,
                })
            }).then(response => response.json())
            .then(resp => {
                console.log(resp)
                if('status' in resp && resp.status === "OK"){
                    this.setState({resetMsg: 'Un email de reinitialisation vous a été envoyé.'})
                }
            })
        }
    }

    toLogin = (e) => {
        e.preventDefault();
        window.location.href = '/login'
    }

    render() { 
        return ( 
            <div style={styles.mainContainer}>
                {this.state.width > 900 ? (
                    <h1 style={{color: '#008081', fontSize: "5em"}}>QUESAQUO</h1>
                ) : (
                    <h1 style={{color: '#008081', fontSize: "3em"}}>QUESAQUO</h1>
                )}
                <div style={styles.loginContainer}>
                    <h1>Reset Mot de passe</h1>
                    <form className="ui form error">
                        <div className="field required">
                            <label>Email</label>
                            <input type="email" name='email'
                            value={this.state.email} 
                            onChange={this.inputChanged} />
                        </div>
                        <button onClick={this.resetPasswd} className="ui button primary" disabled={this.state.email === ''}>Envoyer</button>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span style={{margin: 10}}>{this.state.resetMsg}</span>
                        </div>
                    </form>
                    {this.state.errorMsg !== '' ? 
                        <span style={{margin: 10, color: 'red'}}>{this.state.errorMsg}</span>
                    : null}
                    {this.state.resetMsg !== '' ? 
                        <button onClick={this.toLogin} className="ui button primary" >Retour au Login</button>
                    : null}
                </div>
            </div>
         );
    }
}

var styles = {
    mainContainer:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100vh"
    },
    title:{
        color: '#008081',
        fontSize: "5em"
    },
    loginContainer: {
        backgroundColor: '#008081',
        padding: "3vh",
        color: 'white',
        width: '70%',
    },
}
 
export default withCookies(ResetPasswd);