import React, { useState, useEffect } from 'react';
import { Popup, Icon, Dimmer, Loader, Message, Item,  CardGroup, Card, CardHeader, CardDescription, CardContent, Button } from 'semantic-ui-react'

export default function TablePointInteret(props){

    const [points_interet, setPI] = useState(null);

    useEffect(() => {
        setPI(props.points_interet)
    }, [props.points_interet])

    return (
        <React.Fragment>
            {points_interet !== null ? (
                <div className="ressouce_table">
                    {points_interet.length === 0 ? (
                        <Message>
                            <Message.Header>Vous n'avez pas encore de points d'interet.</Message.Header>
                        </Message>
                    ) : (
                        <div style={{ textAlign: 'left'}}>
                            <CardGroup>
                                {points_interet.map(pi => {
                                    return <Card fluid color='#118080' >
                                        <CardContent onClick={e => {props.choosePointInteret(pi)}} style={{cursor: 'pointer'}}>
                                            <CardHeader>{pi.nom}</CardHeader>
                                            <CardDescription>
                                                <p>Actif: {pi.active ? "Oui" : "Non"}</p>
                                                <p>Flashé {pi.flashed_at.length} fois</p>
                                                <p>Nombre d'article:  {pi.articles.length}</p>
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent extra>
                                            <div className='ui one buttons'>
                                                <Button basic color='green' onClick={props.editPointInteret(pi, 'text')}>
                                                    Modifier
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                })}
                            </CardGroup>
                        </div>
                    )}
                </div>
            ): (
                <Dimmer active inverted>
                    <Loader inverted>Loading Points d'Interet</Loader>
                </Dimmer>
            )}
        </React.Fragment>
    )
}

var styles = {
    editIcon: {
        marginLeft: '1em',
    },
    spanHeader: {
        cursor: 'pointer',
    }
}