import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Container, Button, Icon } from 'semantic-ui-react'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';


class Login extends Component {
    state = {
        credentials: {
            username: '',
            password: '',
            password2: '',
            email: '',
        },
        loading: false,
        selectedLangue: '',
        langues: [],
        errorMsg: '',
        resetMsg: '',
        passwordDisplay: 'password',
        width: null,
    }

    inputChanged = (event) => {
        let cred = this.state.credentials
        cred[event.target.name] = event.target.value
        this.setState({credentials: cred})
    }
    
    componentDidMount = () => {
        if (this.props.cookies.get('FC_token') !== undefined){
            window.location.href = "/userboard"
        }
        this.setState({width: window.innerWidth})
        this.loadLangues();
    }

    loadLangues = () => {
        this.setState({langues: [], selectedLangue: ''} );
        fetch(`${process.env.REACT_APP_API_URL}/api/langues/`, {
            method: 'GET',
        }).then(response => response.json())
          .then(resp => this.setState({langues: resp}))
          .catch(error => console.log(error))
    }

    login = event => {
        event.preventDefault();
        this.setState({loading: true})
        fetch(`${process.env.REACT_APP_API_URL}/auth/`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(this.state.credentials)
            }).then(response => response.json())
            .then(resp =>{
                this.setState({loading: false})
                if(resp.hasOwnProperty('non_field_errors')){
                    this.setState({errorMsg: resp.non_field_errors})
                } else if (resp.hasOwnProperty('token')){
                    this.props.cookies.set('FC_token', resp.token)
                    this.props.cookies.set('FC_perm_lvl', resp.permission_level)
                    this.props.cookies.set('FC_premium', resp.premium)
                    window.location.href = '/dashboard'
                }
            })
            .catch(error => console.log(error))
    }

    selectLangue = event => {
        let langue_id = event.target.value
        let xlang = this.state.langues.filter(l => l.id == langue_id)
        this.setState({selectedLangue: xlang[0]})
    }

    toSignup = () => {
        window.location.href = '/signup'
    }

    toResetPasswd = () => {
        window.location.href = '/resetPwd'
    }

    resetPasswd = () => {
        let host = process.env.REACT_APP_API_URL
        let url = `${host}/api/password_reset/`
        fetch(url,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                emai: this.state.credentials.email,
            })
        }).then(response => response.json())
        .then(resp => {
            if('status' in resp && resp.status === "OK"){
                this.setState({resetMsg: 'Un email de reinitialisation vous a été envoyé.'})
            }
        })
    }

    onGoogleLoginSuccess = (response) => {
        const data = {
            email: response.profileObj.email,
            first_name: response.profileObj.givenName,
            last_name: response.profileObj.familyName
        };
        const headers = {
            'Authorization': response.tokenId,
            'Content-Type': 'application/json'
          };
        
        fetch(`${process.env.REACT_APP_API_URL}/auth/googleId/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => response.json())
        .then(resp =>{
            this.setState({loading: false})
            if(resp.hasOwnProperty('non_field_errors')){
                this.setState({errorMsg: resp.non_field_errors})
            } else if (resp.hasOwnProperty('token')){
                this.props.cookies.set('FC_token', resp.token)
                this.props.cookies.set('FC_perm_lvl', resp.permission_level)
                this.props.cookies.set('FC_premium', resp.premium)
                window.location.href = '/userboard'
            }
        })
        .catch(error => console.log(error))
    }

    onGoogleLoginFailure = (response) => {
        console.log("Google ID Failed")
        console.log(response)
    }

    responseFacebook = (response) => {
        const data = {
            email: response.email,
        };
        const headers = {
            'Content-Type': 'application/json'
          };
        
        fetch(`${process.env.REACT_APP_API_URL}/auth/facebookId/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        }).then(response => response.json())
        .then(resp =>{
            console.log(resp)
        })
        .catch(error => console.log(error))
    }


    render() { 
        let disabled_login = this.state.credentials.username.length === 0 || 
                             this.state.credentials.password.length === 0
        return ( 
            <Container style={styles900.mainContainer}>
                {this.state.width > 900 ? (
                    <h1 style={{color: '#008081', fontSize: "5em"}}>QUESAQUO</h1>
                ) : (
                    <h1 style={{color: '#008081', fontSize: "3em"}}>QUESAQUO</h1>
                )}
                <div style={styles900.loginContainer}>
                    <h1>Connexion</h1>
                    <form className="ui form error">
                        <div className="field required">
                            <label>Email</label>
                            <input type="email" name='username'
                            value={this.state.credentials.username} 
                            onChange={this.inputChanged} />
                        </div>
                        <div className="field required">
                            <label>Mot de passe</label>
                            <input type={this.state.passwordDisplay} name='password' 
                                value={this.state.credentials.password} 
                                onChange={this.inputChanged}
                            />
                        </div>
                        <Button primary disabled={disabled_login} onClick={this.login} loading={this.state.loading}>Connexion</Button>
                        <div className="ui horizontal divider">Pas encore de compte ?</div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span onClick={this.toSignup} className="click-span" style={{margin: 10}}>Créez un compte ici</span>
                            <span onClick={this.toResetPasswd} className="click-span" style={{margin: 10}}>Mot de passe oublié</span>
                            <span style={{margin: 10}}>{this.state.resetMsg}</span>
                        </div>
                        <div>
                            <GoogleLogin 
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                onSuccess={this.onGoogleLoginSuccess} 
                                onFailure={this.onGoogleLoginFailure}
                                cookiePolicy={'single_host_origin'}
                            />
                            <span style={{marginRight:'2rem'}}></span>
                            {/* <FacebookLogin 
                                appId={process.env.REACT_APP_FB_API_ID}
                                autoLoad={false}
                                fields="name,email"
                                callback={this.responseFacebook}
                                scope="public_profile,email"
                                size="small"
                            /> */}
                        </div>
                    </form>
                    {this.state.errorMsg !=='' ? (
                        <div className="ui error message">
                            <i className="close icon" onClick={() => this.setState({errorMsg: ''})}></i>
                            <div className="header">{this.state.errorMsg}</div>
                        </div>
                    ) : null}
                    
                </div>
            </Container>
        )
    }
}

var styles900 = {
    mainContainer:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100vh"
    },
    title:{
        color: '#008081',
        fontSize: "5em"
    },
    loginContainer: {
        backgroundColor: '#008081',
        padding: "3vh",
        color: 'white',
        width: '70%',
    },
}


 
export default withCookies(Login);