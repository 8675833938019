import React, { Component } from 'react';
import { withCookies } from 'react-cookie';


class ResetPasswdConfirm extends Component {
    state = {
        credentials: {
            passwd1: '',
            passwd2: '',
        },
        resetMsg: '',
        errorMsg: '',
        token: null,
        width: null,
    }

    componentDidMount = () => {
        let token = window.location.href.split('?token=')[1]
        this.setState({token: token, width: window.innerWidth})
    }

    inputChanged = (event) => {
        let cred = this.state.credentials
        cred[event.target.name] = event.target.value
        this.setState({credentials: cred})
    }

    sendReset = (e) => {
        e.preventDefault();
        if(this.state.credentials.passwd1 === this.state.credentials.passwd2) {
            let url = `${process.env.REACT_APP_API_URL}/api/password_reset/confirm/`
            fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this.state.token,
                    password: this.state.credentials.passwd2,
                })
            }).then(response => response.json())
            .then(resp => {
                if('status' in resp && resp.status === "OK"){
                    this.setState({resetMsg: 'Votre mot de passe a bien été modifié'})
                }
            })

        } else {
            this.setState({errorMsg: 'Les deux mots de passes doivent être identiques'})
        }
    }

    toLogin = (e) => {
        e.preventDefault();
        window.location.href = '/login'
    }

    render() { 
        let disabled = this.state.credentials.passwd1.length === 0 || 
                        this.state.credentials.passwd2.length === 0
        return ( 
            <div style={styles.mainContainer}>
                {this.state.width > 900 ? (
                    <h1 style={{color: '#008081', fontSize: "5em"}}>QUESAQUO</h1>
                ) : (
                    <h1 style={{color: '#008081', fontSize: "3em"}}>QUESAQUO</h1>
                )}
                <div style={styles.loginContainer}>
                    <h1>Reset Mot de passe</h1>
                    <form className="ui form error">
                        <div className="field required">
                            <label>Mot de passe</label>
                            <input type="password" name='passwd1' 
                                value={this.state.credentials.passwd1} 
                                onChange={this.inputChanged} />
                        </div>
                        <div className="field required">
                            <label>Confirmez Mot de passe</label>
                            <input type="password" name='passwd2' 
                                value={this.state.credentials.passwd2} 
                                onChange={this.inputChanged} />
                        </div>
                        <button onClick={this.sendReset} className="ui button primary" disabled={disabled}>Envoyer</button>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span style={{margin: 10}}>{this.state.resetMsg}</span>
                            <span style={{margin: 10, color: 'red'}}>{this.state.errorMsg}</span>
                        </div>
                        {this.state.resetMsg !== '' ? 
                            <button onClick={this.toLogin} className="ui button primary" >Retour au Login</button>
                        : null}
                    </form>
                </div>
            </div>
         );
    }
}

var styles = {
    mainContainer:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100vh"
    },
    title:{
        color: '#008081',
        fontSize: "5em"
    },
    loginContainer: {
        backgroundColor: '#008081',
        padding: "3vh",
        color: 'white',
        width: '70%',
    },
}
 
export default withCookies(ResetPasswdConfirm);