import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Icon, Menu, Dropdown, Header, Segment } from 'semantic-ui-react'
import MediaQuery from 'react-responsive'


class Navbar extends Component {

    state = {  
        token: this.props.cookies.get('FC_token'),
        permission_level: this.props.cookies.get('FC_perm_lvl'),
        showBurger: false,
    }

    logout = () => {
        this.props.cookies.remove('FC_token')
        this.props.cookies.remove('FC_perm_lvl')
        this.props.cookies.remove('FC_premium')
        window.location.href = '/home'
    }

    login = () => {
        window.location.href = '/login'
    }

    toUserBoard = () => {
        window.location.href = '/userboard'
    }

    toRessources = () => {
        window.location.href = '/dashboard'
    }

    toProfile = () => {
        window.location.href = '/profile'
    }

    toMap = () => {
        window.location.href = '/explore'
    }

    render (){
        return (
            <React.Fragment>
                <MediaQuery minDeviceWidth={1224}>
                    <Menu attached stackable>
                        <Menu.Header>
                            <Menu.Item style={styles.logo} onClick={this.toUserBoard}>
                                QUESAQUO <Icon name="question"/>
                            </Menu.Item>
                        </Menu.Header>
                        {this.state.token === undefined ? (
                            <Menu.Item position="right" onClick={this.login}><Header content="Connexion" /></Menu.Item>
                            ) : (
                            <React.Fragment>
                                <Menu.Item content="Ma Carte" onClick={this.toMap}></Menu.Item>
                                {this.state.permission_level > 0 ? (
                                    <Menu.Item content="Mon Contenu" onClick={this.toRessources}></Menu.Item>
                                ) : null}
                                <Menu.Menu position="right">
                                    <Dropdown item text="Mon compte">
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={this.toProfile}>Mon Profil</Dropdown.Item>
                                            <Dropdown.Item onClick={this.logout}>Deconnexion</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                            </React.Fragment>
                        ) }
                    </Menu>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1223}>
                    <React.Fragment>
                        <Menu attached stackable>
                            <Menu.Header>
                                <Menu.Item style={styles.logo}>
                                    QUESAQUO <Icon name="question"/>
                                </Menu.Item>
                            </Menu.Header>
                            <Menu.Item position="right">
                                {this.state.showBurger ? (
                                    <Icon name="close" onClick={e => {this.setState({showBurger: false})}} />
                                ) : (
                                    <Icon name="bars" onClick={e => {this.setState({showBurger: true})}} />
                                )}
                            </Menu.Item>
                        </Menu>
                        {this.state.showBurger ? (
                            <Segment.Group style={styles.burgerSegment}>
                                {this.state.token === undefined ? (
                                    <Segment onClick={this.login}><Header content="Connexion" /></Segment>
                                    ) : (
                                    <React.Fragment>
                                        <Segment><Header content="Explorer" onClick={this.toMap}/></Segment>
                                        {this.state.permission_level > 0 ? (
                                            <Segment><Header content="Ressources" onClick={this.toRessources}/></Segment>
                                        ) : null}
                                        <Segment><Header content="Mon profil" onClick={this.toProfile}/></Segment>
                                        <Segment onClick={this.logout}><Header content="Deconnexion" /></Segment>
                                    </React.Fragment>
                                )}
                            </Segment.Group>
                        ) : null}
                    </React.Fragment>
                </MediaQuery>
            </React.Fragment>
        )
    }
}

var styles = {
    menu:{
        borderRadius: 0,
    },
    logo: {
        color: '#008080'
    },
    burgerSegment:{
        marginTop: '-1rem',
        textAlign: 'center',
    }
}

export default withCookies(Navbar)