import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Icon, Popup } from 'semantic-ui-react';


export default function FormMonument(props){

    const [edit, setEdit] = useState(false)
    const [nom, setNom] = useState('')
    const [site, setSite] = useState('')


    useEffect(() => {
        setNom(props.monument.nom)
        setSite(props.monument.site)
        setEdit(props.edit)
    }, [props.monument])

    return (
        <div style={{marginTop: '10vh'}}>
            <div style={styles.closeDiv}>
                <Popup
                    trigger={<Icon name='close' link size="large" onClick={props.cancelForm} />}> 
                    Fermer
                </Popup>
            </div>
            <Form style={styles.form}>
                <Form.Field required>
                    <label>Nom</label>
                    <input type="text" name="nom" placeholder="Nom" value={nom} onChange={e => setNom(e.target.value.toString().toUpperCase())}/>
                </Form.Field>
                <Form.Field required>
                    <label>Site</label>
                    <select className="ui fluid dropdown" onChange={e => setSite(e.target.value)} value={site}>
                        {props.site === null ? (<option value=''>Choisir un Site</option>) : null }
                        {props.sites.filter(site => {
                            if (props.site !== null){
                                if(props.site.id === site.id){
                                    return site
                                }
                            } else {
                                return site
                            }
                        }).map(site => {
                            return (<option key={site.id} value={site.id}>{site.nom}</option>)
                        })}
                    </select>
                </Form.Field>
                <Button.Group>
                    <Button positive 
                        disabled={nom === '' || site === ''}
                        onClick={props.saveMonument({nom: nom, site: site})}>Valider</Button>
                    {edit ? (
                        <React.Fragment>
                            <Button.Or />
                            <Button negative onClick={props.deleteMonument}>Supprimer le Monument</Button>
                        </React.Fragment>
                    ) : null}
                </Button.Group>
                { props.errors ? (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>{props.errors}</Message.Header>
                    </Message>
                ): null}  
            </Form>
        </div>
    )
}


var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    closeDiv: {
        width: '50%',
        marginLeft: '25%',
        display: "flex",
        justifyContent: 'flex-end'
    },
}