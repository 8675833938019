import React, {useState, useEffect} from 'react';
import { Header, Segment, Form, Icon, Button, ButtonGroup } from 'semantic-ui-react';
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
const ReactMarkdown = require('react-markdown');

export default function TraductionArticle(props){

    const [titre, setTitre] = useState('')
    const [contenu, setContenu] = useState('')
    const [langue, setLangue] = useState('')
    const [showOriginal, setShowOriginal] = useState(true)

    useEffect(() => {
        setTitre(props.article.titre)
        setContenu(props.article.contenu)
    }, [props.article])

    const saveTraduction = event => {
        event.preventDefault();
        let traduction = {titre: titre, contenu: contenu, langue: langue}
        props.saveTraduction(traduction)
    }

    const selectLangue = langue => {
        setLangue(langue)
        if (props.article.traductions.length > 0){
            let traduction = props.article.traductions.filter(trad => trad.langue === parseInt(langue))
            if(traduction.length > 0){
                let trad = traduction.pop()
                setTitre(trad.titre)
                setContenu(trad.contenu)
            } else {
                setTitre(props.article.titre)
                setContenu(props.article.contenu)
            }
        } else {
            setTitre(props.article.titre)
            setContenu(props.article.contenu)
        }

    }

    const plugins = [
        'header',
        'font-bold',
        'font-italic',
        'list-unordered',
        'list-ordered', 
        'link', 
        'logger',
        'full-screen', 
    ];
    const mdParser = new MarkdownIt();

    return (
        <React.Fragment>
            <div className="ui horizontal divider form-divider">Translate</div>
            <div className="ui grid container fluid">
                {showOriginal ? (
                    <div className="eight wide column">
                        <Segment>
                            <Icon name="left angle" onClick={e => setShowOriginal(false)} style={{cursor: 'pointer'}}/>
                            <Header content={props.article.titre} />
                            <ReactMarkdown source={props.article.contenu} />
                        </Segment>
                    </div>
                ) : (
                    <div className="one wide column">
                        <Segment>
                            <Icon name="right angle" onClick={e => setShowOriginal(true)} style={{cursor: 'pointer'}}/> 
                        </Segment>
                    </div>
                )}
                <div className={`column ${showOriginal ? 'eight wide' : 'fifteen wide'}`}>
                    <Segment>
                        <Form>
                            <Form.Field required>
                                <label>Langue</label>
                                <select className="ui fluid dropdown" onChange={e => selectLangue(e.target.value)} value={langue}>
                                    <option value=''>Choisir une Langue</option>
                                    {props.langues.filter(langue => langue.id !== props.article.langue).map(langue => {
                                        return (<option key={langue.id} value={langue.id}>{langue.nom}</option>)
                                    })}
                                </select>
                            </Form.Field>
                            <Form.Field required>
                                <label>Titre</label>
                                <input type="text" name="titre" placeholder="Titre" value={titre} onChange={e => setTitre(e.target.value.toString().toUpperCase())}/>
                            </Form.Field>
                            <Form.Field required>
                                <label>Contenu</label>
                                <MdEditor
                                    value={contenu}
                                    plugins={plugins}
                                    renderHTML={(text) => mdParser.render(text)}
                                    onChange={({html, text}) => setContenu(text)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <ButtonGroup>
                                    <Button positive onClick={saveTraduction} disabled={titre === '' || contenu === '' || langue === ''}>Enregister</Button>
                                    <Button.Or />
                                    <Button negative onClick={props.cancelForm}>Annuler</Button>
                                </ButtonGroup>
                            </Form.Field>
                        </Form>
                    </Segment>
                </div>
            </div>
        </React.Fragment>
    )
}

var styles = {

}