import "video-react/dist/video-react.css"; // import css
import React, { useState, useEffect } from 'react';
import { Button, Icon, Form, Progress} from 'semantic-ui-react';
import { Input, Item } from 'semantic-ui-react'
import { Player } from 'video-react';


export default function VideosArticles(props){

    const [videos, setVideos] = useState([])
    const [article, setArticle] = useState(null)
    const [nbVideos, setNbVideos] = useState(0)

    useEffect(() => {
        setArticle(props.article)
        setVideos(props.article.videos)
        setNbVideos(props.article.videos.length)
    },[props.article])
    
    const prev = (e) => {
        props.prev(e)
    }
    const next = (e) => {
        props.next(e, article.videos)
    }

    const handleVideo = e => {
        e.preventDefault();
        let video = Array.from(e.target.files).pop()
        props.handleVideo(video)
    }

    const handleTitre = (video, e) => {
        video.titre = e.target.value
        let videos = article.videos
        let index = videos.findIndex(x => x.id === video.id)
        videos[index] = video
        setArticle({...article, [videos]: videos})
    }

    return (
        <React.Fragment>
            {props.article !== null ? (
                <div style={styles.videoPlayer}>
                    {props.article.videos.map(video => {
                        return (
                            <div key={video.id}>
                                <div style={{marginTop: '2vh', marginBottom: '2vh'}}>
                                    <Player playsInline src={video.url} />
                                </div>
                                <Form>
                                    <Form.Group>
                                        <Form.Field width={10}>
                                            <input type="text" placeholder="Titre" value={video.titre} onChange={e => handleTitre(video, e)}/>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                                <Button negative fluid onClick={e => props.deleteVideo(video)}>Supprimer</Button>
                            </div>
                        )
                    })}
                </div>
            ) : null}
            <Form style={styles.form}>
                <Form.Field>
                    <Input type="file" id="video" accept="video/*" onChange={e => handleVideo(e)} loading={props.loading} disabled={props.loading} />
                    {props.loading ? <Progress percent={props.uploadPercent} progress indicating/> : null }
                </Form.Field>
                <Form.Field>
                    <Button.Group>
                        <Button icon labelPosition='left' onClick={e => prev(e)} disabled={props.loading}>
                            <Icon name="left arrow" />
                            Precedent
                        </Button>
                        <Button.Or />
                        <Button icon positive labelPosition='right' onClick={ e => next(e)} disabled={props.loading}>
                            <Icon name="check" />
                            Enregistrer
                        </Button>
                    </Button.Group>
                </Form.Field>
            </Form>
        </React.Fragment>
    )
}

var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    itemgroup: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    trash:{
        color: 'red',
        cursor: 'pointer'
    },
    videoPlayer:{
        width: '50%',
        marginLeft: '25%',
        marginBottom: '5vh',
    }
}