import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Route, BrowserRouter} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import Login from './components/Login';
import Signup from './components/Signup';
import ResetPasswd from './components/ResetPasswd';
import ResetPasswdConfirm from './components/ResetPasswdConfirm';
import Reader from './components/reader/Reader';
import Home from './components/Home';
import UserBoard from './components/UserBoard'
import UserProfile from './components/user/UserProfile';
import Explore from './components/Explore';
import Privacy from './components/Privacy';

const routing = (
  <BrowserRouter>
    <CookiesProvider>
    <Route exact path='/' component={Home} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/home' component={Home} />
      <Route exact path='/signup' component={Signup} />
      <Route exact path='/resetPwd' component={ResetPasswd} />
      <Route exact path='/resetPwdConfirm' component={ResetPasswdConfirm} />
      <Route exact path='/dashboard' component={App} />
      <Route exact path='/userboard' component={UserBoard} />
      <Route exact path='/profile' component={UserProfile} />
      <Route exact path='/reader/:pID' component={Reader} />
      <Route exact path='/explore' component={Explore} />
      <Route exact path='/privacy' component={Privacy} />
    </CookiesProvider>
  </BrowserRouter>
)
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
