import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Icon, Popup } from 'semantic-ui-react';


export default function FormSite(props){

    const [edit, setEdit] = useState(false)
    const [nom, setNom] = useState('')
    const [adresse, setAdresse] = useState('')
    const [ville, setVille] = useState('')


    useEffect(() => {
        setNom(props.site.nom)
        setAdresse(props.site.adresse)
        setVille(props.site.ville)
        setEdit(props.edit)
    }, [props.site])

    return (
        <div style={{marginTop: '10vh'}}>
            <div style={styles.closeDiv}>
                <Popup
                    trigger={<Icon name='close' link size="large" onClick={props.cancelForm} />}> 
                    Fermer
                </Popup>
            </div>
            <Form style={styles.form}>
                <Form.Field required>
                    <label>Nom</label>
                    <input type="text" name="nom" placeholder="Nom" value={nom} onChange={e => setNom(e.target.value.toString().toUpperCase())}/>
                </Form.Field>
                <Form.Field required>
                    <label>Adresse</label>
                    <input type="text" name="adresse" placeholder="Adresse" value={adresse} onChange={e => setAdresse(e.target.value)}/>
                </Form.Field>
                <Form.Field required>
                    <label>Ville</label>
                    <select className="ui fluid dropdown" onChange={e => setVille(e.target.value)} value={ville}>
                        {props.ville === null ? (<option value=''>Choisir une Ville</option>) : null }
                        {props.villes.filter(ville => {
                            if (props.ville !== null){
                                if(props.ville.id === ville.id){
                                    return ville
                                }
                            } else {
                                return ville
                            }
                        }).map(ville => {
                            return (<option key={ville.id} value={ville.id}>{ville.nom}</option>)
                        })}
                    </select>
                </Form.Field>
                <Button.Group>
                    <Button positive 
                        disabled={nom === '' ||ville === ''}
                        onClick={props.saveSite({nom: nom, adresse: adresse, ville: ville})}>Valider</Button>
                    {edit ? (
                        <React.Fragment>
                            <Button.Or />
                            <Button negative onClick={props.deleteSite}>Supprimer le Site</Button>
                        </React.Fragment>
                    ) : null}
                </Button.Group>
                { props.errors ? (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>{props.errors}</Message.Header>
                    </Message>
                ): null}  
            </Form>
        </div>
    )
}


var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    closeDiv: {
        width: '50%',
        marginLeft: '25%',
        display: "flex",
        justifyContent: 'flex-end'
    },
}