import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { Breadcrumb, Message, MessageContent, MessageHeader } from 'semantic-ui-react'
import { Icon } from 'semantic-ui-react';
import Ville from './ville/Ville';
import Site from './site/Site';
import Monument from './monument/Monument';
import PointInteret from './point_interet/PointInteret';
import Article from './article/Article';

function Ressources (props) {

    const [breadSections, setBreadSections] = useState([]);
    const [ressourceType, setRessourceType] = useState(null)
    const [subRessourceType, setSubRessourceType] = useState(null)
    const [ressource, setRessource] = useState(null)

    useEffect(() => {
        loadSections(props.ressource, props.ressourceType, props.ressourceLevel)
    }, [props])

    const loadSections = (ressource, ressourceType, ressourceLevel) => {
        if (ressourceType !== ressource){
            let sections = breadSections
            sections = sections.filter((section) => section.level > ressourceLevel)
            sections.push({key: ressource.nom, content: ressource.nom, link: true, level: ressourceLevel, onClick: () => loadSections(ressource, ressourceType, ressourceLevel)})
            setBreadSections(sections)
            setRessourceType(ressourceType)
            setRessource(ressource)

            switch(ressourceType){
                case 'territoires':
                    setSubRessourceType('villes')
                    break;
                case 'villes':
                    setSubRessourceType('sites')
                    break;
                case 'sites':
                    setSubRessourceType('monuments')
                    break;
                case 'monuments':
                    setSubRessourceType('points_interet')
                    break;
                case 'points_interet':
                    setSubRessourceType('articles')
                    break;
                case 'articles':
                    setSubRessourceType(null)
                    break;
            }
        }
    }

    return (
        <div className="thirteen wide column board" style={{padding: '3em'}}>
            <div style={{display: 'flex', justifyContent:'right'}}>
                <h3><a href="/profile" style={{color: 'black', }}><Icon name='user'></Icon>Mon compte</a></h3>
            </div>
            {subRessourceType !== null ? (<h1>Liste des {subRessourceType}</h1>): null }
            <Breadcrumb icon='right angle' sections={breadSections} />
            {ressourceType === 'territoires' ? <Ville  choosenTerritoire={ressource} loadSections={loadSections}/> : null}
            {ressourceType === 'villes' ? <Site  choosenVille={ressource}  loadSections={loadSections} /> : null}
            {ressourceType === 'sites' ? <Monument  choosenSite={ressource}  loadSections={loadSections} /> : null}
            {ressourceType === 'monuments' ? <PointInteret  choosenMonument={ressource}  loadSections={loadSections} /> : null}
            {ressourceType === 'points_interet' ? <Article  choosenPointInteret={ressource}  loadSections={loadSections} /> : null}

            {ressourceType === null ?
            <div>
                  <Message positive>
                    <MessageHeader>Bienvenue sur Quesaquo</MessageHeader>
                    <p></p>
                    <p>Choisissez un contenu dans le menu pour organiser et gérer vos articles.</p>
                    <p>Cliquez sur la carte pour visualiser les points d'interets géolocalisés</p>
                </Message>
            </div> : null}
        </div>
    )
}

export default withCookies(Ressources);