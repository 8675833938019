import React, { Component } from 'react';
import { Dimmer, Loader, Table, Tab, Message, Button } from 'semantic-ui-react'
import { withCookies } from 'react-cookie';
import { confirmAlert } from 'react-confirm-alert';
import TableSite from './TableSite';
import FormSite from './FormSite';
import Searcher from '../Searcher';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Site extends Component {
    
    state = { 
        token: this.props.cookies.get('FC_token'),
        perm_level: this.props.cookies.get('FC_perm_lvl'),
        requiredPermLevel: 4,
        sites: null,
        villes: [],
        selectedSite: null,
        errors: null,
        edit: false,
    }

    componentDidMount(){
        this.loadSites();
        this.loadVilles();
    }

    loadVilles = () => {
        this.setState({villes: [], selectedVille: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/villes/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({villes: resp}))
          .catch(error => console.log(error))
    }

    loadSites = () => {
        this.setState({sites: null, selectedSite: null} );
        fetch(`${process.env.REACT_APP_API_URL}/api/sites/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({sites: resp}))
          .catch(error => console.log(error))
    }

    setSites = (sites) => {
        this.setState({sites: sites})
    }

    inputChanged = (event) => {
        let site = this.state.selectedSite;
        site[event.target.name] = event.target.value.toString().toUpperCase();
        this.setState({selectedSite: site});
    }

    selectVille = event => {
        let ville_id = event.target.value
        let site = this.state.selectedSite
        site.ville = ville_id
        this.setState({selectedSite: site})
    }

    saveSite = site => event => {
        event.preventDefault();
        let ss = this.state.selectedSite
        ss.nom = site.nom
        ss.adresse = site.adresse
        ss.ville = site.ville
        this.setState({selectedSite: ss})

        let method = this.state.edit ? "PUT" : "POST"
        let url = this.state.edit ? `${process.env.REACT_APP_API_URL}/api/sites/${this.state.selectedSite.id}/` :
                                    `${process.env.REACT_APP_API_URL}/api/sites/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedSite)
        }).then(response => response.json() ).catch(error => console.log(error))
        .then(resp => {
            if(Array.isArray(resp.non_field_errors)){
                this.setState({errors: resp.non_field_errors})
            } else {
                if(this.state.edit){
                    this.loadSites();
                } else {
                    this.setState({sites: [...this.state.sites, resp], selectedSite: null, edit: false});
                }
            }
        })
        .catch(error => console.log(error))
    }

    cancelForm = (event) => {
        event.preventDefault();
        this.setState({selectedSite: null, edit: false, errors: null});
    }

    editSite = site => event => {
        event.preventDefault();
        this.setState({selectedSite: site, edit:true})
    }

    confirmDelete = event => {
        event.preventDefault();
        confirmAlert({
            title: "Confirmer supression",
            message: `Etes vous sur de vouloir supprimer le site ${this.state.selectedSite.nom} ?`,
            buttons: [
                {label: "Oui", onClick: () => this.deleteSite(event)},
                {label: "Non", onClick: () => this.cancelForm(event)},
            ]
        });

    }

    newSite = () => {
        if (this.props.choosenVille !== null){
            this.setState({edit: false, selectedSite: {nom:'', adresse:'', ville: this.props.choosenVille.id}})
        } else {
            this.setState({edit: false, selectedSite: {nom:'', adresse:'', ville: ''}})
        }
    }

    deleteSite = event => {
        event.preventDefault();
        let method = "DELETE"
        let url = `${process.env.REACT_APP_API_URL}/api/sites/${this.state.selectedSite.id}/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedSite)
        }).then(resp => {
            if(resp.ok){
                const sites = this.state.sites.filter(site => site.id !== this.state.selectedSite.id);
                this.setState({sites: sites, edit: false, selectedSite: null})
            } else {
                this.setState({errors: "Vous ne pouvez pas supprimer ce Site car il contient encore au moins un Monument."})
            }
        })
        .catch(error => console.log(error))
    }

    cleanErrors = () => {
        this.setState({errors: null})
    }

    getNomVille = (site) => {
        let villes = this.state.villes.filter(ville => ville.id === site.ville)
        if (villes.length > 0) {
            return villes[0].nom
        } else {
            return ""
        }
    }

    chooseSite = site => {
        this.props.loadSections(site, 'sites', 4);
    }

    getSites = (v) => {
        if(this.state.sites !== null){
            let sites = this.state.sites.filter(site => {
                if(v !== null){
                    if(site.ville === v.id){
                        return site
                    }                                            
                } else {
                    return site
                }
            })
            return sites
        }
        return null
    }

    render() { 
        const v = this.props.choosenVille
        const sites = this.getSites(v);
        
        return ( 
            <div>
                <div style={{textAlign: 'left'}}>
                    {/* FORM SITES */}
                    {this.state.perm_level >= this.state.requiredPermLevel && this.state.selectedSite !== null ? (
                        <FormSite 
                            site={this.state.selectedSite}
                            ville={this.props.choosenVille}
                            villes={this.state.villes}
                            deleteSite={this.confirmDelete}
                            cancelForm={this.cancelForm} 
                            edit={this.state.edit}
                            saveSite={this.saveSite}
                            errors={this.state.errors}
                            cleanErrors={this.cleanErrors}
                             
                        />
                    ) : (
                        <div>
                            {this.state.perm_level >= this.state.requiredPermLevel ? (
                                <div>
                                    <div style={{margin: '4rem'}}>
                                        <Button positive size='tiny' onClick={this.newSite}>CREER SITE</Button>
                                    </div>
                                    {/* <Searcher 
                                        items={this.state.sites} 
                                        setItems={this.setSites}
                                        resetSearch={this.loadSites}
                                    /> */}
                                    <TableSite 
                                        sites={sites}
                                        editSite={this.editSite}
                                        chooseSite={this.chooseSite}
                                        getNomVille={this.getNomVille}
                                    />
                                </div>
                            ) : null }
                        </div>
                    )}
                </div>
            </div>
         );
    }
}
 
export default withCookies(Site);