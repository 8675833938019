import React, { Component } from 'react';
import { Step, Button, Popup, Icon } from 'semantic-ui-react'
import { withCookies } from 'react-cookie';
import TableArticles from './TableArticles';
import Searcher from '../Searcher';
import FormArticle from './FormArticle';
import ImagesArticles from './ImagesArticles';
import VideosArticle from './VideosArticle';
import AudioArticle from './AudioArticle';
import TraductionArticle from './TraductionArticle';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MarkdownIt from 'markdown-it'
import 'react-markdown-editor-lite/lib/index.css';
import EXIF from "exif-js"
const Compress = require('compress.js')
const axios = require('axios')



class Article extends Component {
    state = { 
        token: this.props.cookies.get('FC_token'),
        perm_level: this.props.cookies.get('FC_perm_lvl'),
        requiredPermLevel: 1,
        articles: null,
        points_interet: [],
        selectedArticle: null,
        errors: null,
        edit: false,
        translate: false,
        translatedArticle: null,
        selectedLangue: '',
        langues: [],
        uploadedFiles: [],
        uploadedFilesPreview: [],
        uploadPercent: 0,
        loading: false,
        step: 'text',
    }

    componentDidMount(){
        this.loadArticles();
        this.loadPointsInteret();
        this.loadLangues();
    }
    
    loadPointsInteret = () => {
        this.setState({points_interet: []} );
        fetch(`${process.env.REACT_APP_API_URL}/api/points_interet/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
        .then(resp => this.setState({points_interet: resp}))
        .catch(error => console.log(error))
    }

    loadArticles = () => {
        this.setState({selectedArticle: null, edit: false})
        fetch(`${process.env.REACT_APP_API_URL}/api/articles/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            }
        }).then(response => response.json())
          .then(resp => this.setState({articles: resp}))
          .catch(error => console.log(error))
    }

    setArticles = (articles) => {
        this.setState({articles: articles})
    }

    loadLangues = () => {
        this.setState({langues: [], selectedLangue: ''} );
        fetch(`${process.env.REACT_APP_API_URL}/api/langues/`, {
            method: 'GET',
        }).then(response => response.json())
          .then(resp => this.setState({langues: resp}))
          .catch(error => console.log(error))
    }

    editArticle = article => event => {
        event.preventDefault();
        this.setState({selectedArticle: article, edit:true, translate: false, step: "text"})
    }
    
    getNomPi = (article) => {
        let pis = this.state.points_interet.filter(pi => pi.id === article.point_interet)
        if (pis.length > 0) {
            return pis[0].nom
        } else {
            return ""
        }
    }

    getNomLangue = (article) => {
        let langues = this.state.langues.filter(langue => langue.id === article.langue)
        if (langues.length > 0) {
            return langues[0].nom
        } else {
            return ""
        }
    }

    traductionChanged = event => {
        let article = this.state.translatedArticle;
        if(event.target.name === 'contenu') {
            article[event.target.name] = event.target.value.toString()
        } else {
            article[event.target.name] = event.target.value.toString().toUpperCase();
        }
        this.setState({translatedArticle: article});
    }

    selectPI = event => {
        let pi_id = event.target.value
        let article = this.state.selectedArticle
        article.point_interet = pi_id
        this.setState({selectedArticle: article})
    }

    selectLangueTranslate = event => {
        this.setState({translatedArticle: {titre:'', contenu: '', langue: ''}})
        let langue_id = event.target.value
        let article = this.state.selectedArticle
        if(article.traductions.length > 0){
            let trads = article.traductions.filter(art => art.langue === langue_id)
            if(trads.length > 0){
                let trad = trads[0]
                this.setState({translatedArticle: trad})
            } else {
                let trad = {titre:'', contenu: '', langue: ''}
                trad.langue = langue_id
                this.setState({translatedArticle: trad})
            }
        } else {
            let trad = {titre:'', contenu: '', langue: ''}
            trad.langue = langue_id
            this.setState({translatedArticle: trad})
        }
    }

    saveArticle = article => event => {
        event.preventDefault();
        this.setState({loading: true})
        if(this.state.selectedArticle !== null){
            let sa = this.state.selectedArticle
            sa.titre = article.titre
            sa.contenu = article.contenu
            sa.point_interet = article.point_interet
            sa.langue = article.langue
            sa.premium = article.premium
            sa.published = article.published
            this.setState({selectedArticle: sa})
        } else {
            this.setState({selectedArticle: article})
        }
        
        let method = this.state.edit ? "PUT" : "POST"
        let url = this.state.edit ? `${process.env.REACT_APP_API_URL}/api/articles/${this.state.selectedArticle.id}/` :
                                    `${process.env.REACT_APP_API_URL}/api/articles/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedArticle)
        }).then(response => response.json() ).catch(error => console.log(error))
        .then(resp => {
            if(Array.isArray(resp.non_field_errors)){
                this.setState({errors: resp.non_field_errors})
            } else {
                if(this.state.edit){
                    let arts = this.state.articles
                    let index = arts.findIndex(a => a.id === resp.id)
                    arts[index] = resp
                    this.setState({articles: arts, selectedArticle: resp})
                } else {
                    this.setState({articles: [...this.state.articles, resp], selectedArticle: resp});
                }
                this.loadPointsInteret();
                this.setState({loading: false})
                this.toImage();
            }
        })
        .catch(error => console.log(error))
    }

    saveTraduction = traduction => {
        fetch(`${process.env.REACT_APP_API_URL}/api/articles/${this.state.selectedArticle.id}/translate/`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(traduction)
        }).then(response => response.json() ).catch(error => console.log(error))
          .then(resp => {
                console.log(resp);
                this.loadArticles();
          })
    }

    // IMAGES HANDLING
    handleImages = (event, position) => {
        this.setState({loading: true})
        const compress = new Compress()
        let images = Array.from(event.target.files)

        // for(const img of images){
        //     EXIF.getData(img, function(){
        //         console.log(EXIF.getTag(this, 'Orientation'));
        //     })
        // }

        compress.compress(images, {
            size: 1,
            quality: .75, 
        }).then((data) => {
            for(const img of data){
                const base64str = img.data
                const imgExt = img.ext
                if(imgExt === "image/jpeg"){
                    console.log(imgExt)
                }
                const file = Compress.convertBase64ToFile(base64str, imgExt)
                file.name = img.alt
                file.width = Math.round(img.endWidthInPx)
                file.height = Math.round(img.endHeightInPx)
                this.postImageToS3(file, position)
            }
        })
    }

    postImageToS3 = (image, position) => {
        this.setState({loading: true})
        let article = this.state.selectedArticle;
        let form_data = new FormData();
        let titre = article.titre.replace(/\s/g, '_')
        let filename = `media/images/${titre}/${image.name}`
        form_data.append('filename', filename);
        form_data.append('content_type', "image");
        // GET PRESIGNED POST
        fetch(`${process.env.REACT_APP_API_URL}/api/users/get_s3_url/`,{
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            },
            body: form_data
        }).then(resp => resp.json())
        .then(presignedPostData => {
            let form_data = new FormData();
            Object.keys(presignedPostData.fields).forEach(key => {
                form_data.append(key, presignedPostData.fields[key])
            })
            form_data.append('file', image)
            axios({
                method: 'POST',
                url: presignedPostData.url,
                data: form_data,
                onUploadProgress: (p) => {
                    let upPercent = parseInt((p.loaded / p.total) * 100)
                    this.setState({uploadPercent: upPercent})
                }
            })
            .then(resp => {
                this.setState({loading: false})
                this.postImageToDB(image, position)
            })
            .catch(error => console.log(error))
        })
    }

    postImageToDB = (image, position) => {
        this.setState({loading: true})
        let article = this.state.selectedArticle;
        let form_data = new FormData();
        let titre = article.titre.replace(/\s/g, '_')
        let filename = `media/images/${titre}/${image.name}`
        form_data.append('height', image.height)
        form_data.append('width', image.width)
        form_data.append('filename', filename)
        form_data.append('legende', '')
        form_data.append('article', article.id)
        form_data.append('position', position)
        fetch(`${process.env.REACT_APP_API_URL}/api/images/`,{
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            },
            body: form_data
        }).then(resp => resp.json())
        .then(resp => {
            console.log(resp)
            let article = this.state.selectedArticle
            let images = article.images
            images = [...images, resp]
            article.images = images
            this.setState({selectedArticle: null})
            this.setState({selectedArticle: article, loading: false, uploadPercent: 0})
        }).catch(error => {
            console.log(error)
        })
    }

    deleteImage = (image, evt) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/images/${image.id}/`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
        }).then(resp => {
            if(resp.ok){
                let article = this.state.selectedArticle
                let images = article.images
                let index = images.findIndex(x => x.id === image.id)
                images.splice(index, 1)
                article.images = images
                this.setState({selectedArticle: null})
                this.setState({selectedArticle: article})
            }
        })
        .catch(error => console.log(error))
    }

    saveLegend = (image, article) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/images/${image.id}/legende/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify({legende: image.legende})
        }).then(resp => {
            let index = article.images.findIndex(x => x.id === image.id)
            article.images[index] = image
            this.setState({selectedArticle: null})
            this.setState({selectedArticle: article})
        })
        .catch(error => console.log(error))
    }

    savePosition = (image, article) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/images/${image.id}/position/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify({position: image.position})
        }).then(resp => {
            let index = article.images.findIndex(x => x.id === image.id)
            article.images[index] = image
            this.setState({selectedArticle: null})
            this.setState({selectedArticle: article})
        })
        .catch(error => console.log(error))
    }

    // VIDEO HANDLING
    handleVideo = video => {
        this.setState({loading: true})
        this.postVideoToS3(video)
    }

    saveVideos = (e, videos) => {
        videos.forEach(video => {
            fetch(`${process.env.REACT_APP_API_URL}/api/videos/${video.id}/add_title/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${this.state.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({titre: video.titre})
            }).then(response => response.json())
            .then(resp => {
                this.toAudio();
            })
            .catch(error => console.log(error))
        })
    }

    postVideoToS3 = video => {
        let article = this.state.selectedArticle;
        let form_data = new FormData();
        let titre = article.titre.replace(/\s/g, '_')
        let filename = `media/videos/${titre}/${video.name}`
        form_data.append('filename', filename);
        form_data.append('content_type', "video");
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/api/users/get_s3_url/`,
            headers: {
                'Authorization' : `Token ${this.state.token}`,
                'Content-Type'  :'multipart/form-data'
            },
            data: form_data
        }).then(resp => {
            let form_data = new FormData();
            Object.keys(resp.data.fields).forEach(key => {
                form_data.append(key, resp.data.fields[key])
            })
            form_data.append('file', video)
            axios({
                method: 'POST',
                url: resp.data.url,
                data: form_data,
                onUploadProgress: (p) => {
                    let upPercent = parseInt((p.loaded / p.total) * 100)
                    this.setState({uploadPercent: upPercent})
                }
            })
            .then(resp => {
                this.setState({loading: false})
                this.postVideoToDB(video)
            })
            .catch(error => console.log(error))

        })
        .catch(error => console.log(error))
    }

    postVideoToDB = video => {
        this.setState({loading: true})
        let article = this.state.selectedArticle;
        let form_data = new FormData();
        let titre = article.titre.replace(/\s/g, '_')
        let filename = `media/videos/${titre}/${video.name}`
        form_data.append('filename', filename)
        form_data.append('article', article.id)
        // form_data.append('titre', video.name)
        fetch(`${process.env.REACT_APP_API_URL}/api/videos/`,{
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            },
            body: form_data
        }).then(resp => resp.json())
        .then(resp => {
            let article = this.state.selectedArticle
            let videos = article.videos
            videos = [...videos, resp]
            article.videos = videos
            this.setState({selectedArticle: null})
            this.setState({selectedArticle: article, loading: false})
        }).catch(error => {
            console.log(error)
        })
    }

    deleteVideo = video => {
        fetch(`${process.env.REACT_APP_API_URL}/api/videos/${video.id}/`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
        }).then(resp => {
            if(resp.ok){
                let article = this.state.selectedArticle
                let videos = article.videos
                let index = videos.findIndex(x => x.id === video.id)
                videos.splice(index, 1)
                article.videos = videos
                this.setState({selectedArticle: null})
                this.setState({selectedArticle: article})
            }
        })
        .catch(error => console.log(error))
    }

    // AUDIO HANDLING
    handleAudio = audio => {
        this.setState({loading: true})
        this.postAudioToS3(audio)
    }

    saveAudios = (e, audios) => {
        audios.forEach(audio => {
            fetch(`${process.env.REACT_APP_API_URL}/api/audios/${audio.id}/add_title/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${this.state.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({titre: audio.titre})
            }).then(response => response.json())
            .then(resp => {
                this.cancelForm();
            })
            .catch(error => console.log(error))
        })
    }

    postAudioToS3 = audio => {
        let article = this.state.selectedArticle;
        let form_data = new FormData();
        let titre = article.titre.replace(/\s/g, '_')
        let filename = `media/audio/${titre}/${audio.name}`
        form_data.append('filename', filename);
        form_data.append('content_type', "audio");

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/api/users/get_s3_url/`,
            headers: {
                'Authorization' : `Token ${this.state.token}`,
                'Content-Type'  :'multipart/form-data'
            },
            data: form_data
        }).then(resp => {
            let form_data = new FormData();
            Object.keys(resp.data.fields).forEach(key => {
                form_data.append(key, resp.data.fields[key])
            })
            form_data.append('file', audio)
            axios({
                method: 'POST',
                url: resp.data.url,
                data: form_data,
                onUploadProgress: (p) => {
                    let upPercent = parseInt((p.loaded / p.total) * 100)
                    this.setState({uploadPercent: upPercent})
                }
            })
            .then(resp => {
                this.setState({loading: false})
                this.postAudioToDB(audio)
            })
            .catch(error => console.log(error))

        })
        .catch(error => console.log(error))
    }

    postAudioToDB = audio => {
        this.setState({loading: true})
        let article = this.state.selectedArticle;
        let form_data = new FormData();
        let titre = article.titre.replace(/\s/g, '_')
        let filename = `media/audio/${titre}/${audio.name}`
        form_data.append('filename', filename)
        form_data.append('article', article.id)
        fetch(`${process.env.REACT_APP_API_URL}/api/audios/`,{
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.state.token}`,
            },
            body: form_data
        }).then(resp => resp.json())
        .then(resp => {
            console.log(resp)
            let article = this.state.selectedArticle
            let audios = article.audios
            audios = [...audios, resp]
            article.audios = audios
            this.setState({selectedArticle: null})
            this.setState({selectedArticle: article, loading: false})
        }).catch(error => {
            console.log(error)
        })
    }

    deleteAudio = audio => {
        fetch(`${process.env.REACT_APP_API_URL}/api/audios/${audio.id}/`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
        }).then(resp => {
            if(resp.ok){
                let article = this.state.selectedArticle
                let audios = article.audios
                let index = audios.findIndex(x => x.id === audio.id)
                audios.splice(index, 1)
                article.audios = audios
                this.setState({selectedArticle: null})
                this.setState({selectedArticle: article})
            }
        })
        .catch(error => console.log(error))
    }

    // DELETE 
    confirmDelete = event => {
        event.preventDefault();
        confirmAlert({
            title: "Confirmer supression",
            message: `Etes vous sur de vouloir supprimer l'article ${this.state.selectedArticle.titre} ?`,
            buttons: [
                {label: "Oui", onClick: () => this.deleteArticle(event)},
                {label: "Non", onClick: () => this.cancelForm(event)},
            ]
        });
    }

    deleteArticle = event => {
        event.preventDefault();
        let method = "DELETE"
        let url = `${process.env.REACT_APP_API_URL}/api/articles/${this.state.selectedArticle.id}/`
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.state.token}`,
            },
            body: JSON.stringify(this.state.selectedArticle)
        }).then(resp => {
            this.loadPointsInteret();
            const articles = this.state.articles.filter(article => article.id !== this.state.selectedArticle.id);
            this.setState({articles: articles, edit: false, selectedArticle: null})
        })
        .catch(error => console.log(error))
    }

    cleanErrors = () => {
        this.setState({errors: null})
    }

    cancelForm = (event) => {
        this.setState({selectedArticle: null, edit: false, translate: false, errors: null,
                         translatedArticle: null, toUpload:0, uploaded: 0, uploadedFilesPreview: []})
        // this.loadArticles();
    }

    toggleTranslate = article => event => {
        event.preventDefault();
        let newArt = {titre:'', contenu:'', langue: ''}
        this.setState({selectedArticle: article, translate: true, edit: false,
                       translatedArticle: newArt})
    }

    handleEditorChangeTranslate = ({html, text}) => {    
        let article = this.state.translatedArticle;
        article.contenu = text
        this.setState({selectedArticle: article})
    }

    imageName = (image) => {
        return image.image.split('/').pop()
    }

    legendeChange = (image) => event => {
        let article = this.state.selectedArticle
        image.legende = event.target.value
        let index = article.images.findIndex(x => x.id === image.id)
        article.images[index] = image
        this.setState({selectedArticle: article})
    }
    
    newArticle = () => {
        if (this.props.choosenPointInteret !== null){
            this.setState({edit: false, step:'text', selectedArticle: {titre:'', contenu:'', point_interet: this.props.choosenPointInteret.id, 
            langue:'', premium: false, published: false, images: [], videos: [], audios: []}})
        } else {
            this.setState({edit: false, step:'text', selectedArticle: {titre:'', contenu:'', point_interet: '',
             langue:'', premium: false, published: false, images: [], videos: [], audios: []}})
        }
    }

    getArticles = (p) => {
        if(this.state.articles !== null){
            let articles = this.state.articles.filter(article => {
                if(p !== null){
                    if(article.point_interet === p.id){
                        return article
                    }                                            
                } else {
                    return article
                }
            })
            return articles
        }
        return null
    }

    readArticle = (article) => {
        let url = `/reader/${article.point_interet}`;
        let win = window.open(url, '_blank');
        win.focus();
    }

    toText = (evt) => {
        this.setState({step: "text"})
    }

    toImage = (evt) => {
        this.setState({step: "images"})
    }

    toVideo = (evt) => {
        this.setState({step: "videos"})
    }

    toAudio = (evt) => {
        this.setState({step: "audio"})
    }


    render() { 
        const a = this.state.selectedArticle;
        const t = this.state.translatedArticle;
        const isDisabled = this.state.selectedArticle === null || 
                           this.state.selectedArticle.titre.length === 0 ||
                           this.state.selectedArticle.contenu.length === 0 ||
                           this.state.selectedArticle.point_interet === '' ||
                           this.state.selectedArticle.langue === ''
        const p = this.props.choosenPointInteret
        const mdParser = new MarkdownIt();
        const plugins = ['header', 'fonts', 'table', 'clear', 'logger', 'mode-toggle', 'full-screen']
        const articles = this.getArticles(p);

        return ( 
            <div>
                <div>
                    {this.state.perm_level >= this.state.requiredPermLevel && this.state.selectedArticle !== null ? (
                        <div>
                            {this.state.translate ? (
                            <TraductionArticle 
                                article={this.state.selectedArticle}
                                langues={this.state.langues}
                                saveTraduction={this.saveTraduction}
                                cancelForm={this.cancelForm}
                            /> 
                            ) : (
                                <div>
                                    <div style={styles.closeDiv}>
                                        <Popup
                                            trigger={<Icon name='close' link size="large" onClick={this.cancelForm} />}> 
                                            Fermer
                                        </Popup>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                    <Step.Group>
                                        <Step active={this.state.step === "text"}
                                            onClick={this.toText} style={{cursor: 'pointer'}}>
                                            <Step.Content onClick={this.toText} style={{cursor: 'pointer'}}>
                                                <Step.Title>Texte</Step.Title>
                                                <Step.Description>Ajouter le contenu</Step.Description>
                                            </Step.Content>
                                        </Step>
                                        {this.state.selectedArticle.titre !== '' ? (
                                            <React.Fragment>
                                                <Step active={this.state.step === "images"} 
                                                    onClick={this.toImage} style={{cursor: 'pointer'}}>
                                                    <Step.Content>
                                                        <Step.Title>Images</Step.Title>
                                                        <Step.Description>Ajouter des images</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                                <Step active={this.state.step === "videos"}
                                                    onClick={this.toVideo} style={{cursor: 'pointer'}}>
                                                    <Step.Content>
                                                        <Step.Title>Videos</Step.Title>
                                                        <Step.Description>Ajouter des videos</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                                <Step active={this.state.step === "audio"}
                                                    onClick={this.toAudio} style={{cursor: 'pointer'}}>
                                                    <Step.Content>
                                                        <Step.Title>Audio</Step.Title>
                                                        <Step.Description>Ajouter des guide audio</Step.Description>
                                                    </Step.Content>
                                                </Step>
                                            </React.Fragment>
                                        ) : null}
                                    </Step.Group>
                                    </div>

                                    {this.state.step === "text" ? (
                                        <FormArticle 
                                            article={this.state.selectedArticle}
                                            point_interet={this.props.choosenPointInteret}
                                            points_interet={this.state.points_interet}
                                            langues={this.state.langues}
                                            deleteArticle={this.confirmDelete}
                                            cancelForm={this.cancelForm} 
                                            edit={this.state.edit}
                                            saveArticle={this.saveArticle}
                                            errors={this.state.errors}
                                            cleanErrors={this.cleanErrors}
                                            loading={this.state.loading}
                                        />
                                    ) : null }

                                    { this.state.step === "images" ? (
                                        <ImagesArticles 
                                            handleImage={this.handleImages}
                                            article={this.state.selectedArticle}
                                            deleteImage={this.deleteImage}
                                            prev={this.toText}
                                            next={this.toVideo}
                                            loading={this.state.loading}
                                            saveLegend={this.saveLegend}
                                            savePosition={this.savePosition}
                                            uploadPercent={this.state.uploadPercent}
                                        />
                                    ) : null }

                                    {this.state.step === "videos" ? (
                                        <VideosArticle 
                                            article={this.state.selectedArticle}
                                            prev={this.toImage}
                                            next={this.saveVideos}
                                            loading={this.state.loading}
                                            handleVideo={this.handleVideo}
                                            deleteVideo={this.deleteVideo}
                                            uploadPercent={this.state.uploadPercent}
                                        />
                                    ) : null }

                                    {this.state.step === "audio" ? (
                                        <AudioArticle 
                                            article={this.state.selectedArticle}
                                            prev={this.toVideo}
                                            next={this.saveAudios}
                                            loading={this.state.loading}
                                            handleAudio={this.handleAudio}
                                            deleteAudio={this.deleteAudio}
                                            uploadPercent={this.state.uploadPercent}
                                        />
                                    ) : null }                                    
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {this.state.perm_level >= this.state.requiredPermLevel ? (
                                <div>
                                    <div style={{margin: '4rem'}}>
                                        <Button positive size='tiny' onClick={this.newArticle}>ECRIRE ARTICLE</Button>
                                    </div>
                                    {/* <Searcher 
                                        items={this.state.articles} 
                                        setItems={this.setArticles}
                                        resetSearch={this.loadArticles}
                                    /> */}
                                    <TableArticles
                                        articles={articles}
                                        editArticle={this.editArticle}
                                        toggleTranslate={this.toggleTranslate}
                                        readArticle={this.readArticle}
                                        getNomPi={this.getNomPi}
                                        getNomLangue={this.getNomLangue}
                                    />
                                </div>
                            ) : null}
                        </div>   
                    )}
                </div>
            </div>
         );
    }
}

var styles = {
    closeDiv: {
        width: '75%',
        marginLeft: '12%',
        display: "flex",
        justifyContent: 'flex-end',
        marginTop: '5vh',
        marginBottom: '5vh'
    },
}
 
export default withCookies(Article);