import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Icon, Popup } from 'semantic-ui-react';
import { CountryDropdown } from 'react-country-region-selector';


export default function FormVille(props){

    const [edit, setEdit] = useState(false)
    const [nom, setNom] = useState('')
    const [code_postal, setCP] = useState('')
    const [pays, setPays] = useState('')
    const [territoire, setTerritoire] = useState('')


    useEffect(() => {
        setNom(props.ville.nom)
        setCP(props.ville.code_postal)
        setPays(props.ville.pays)
        setTerritoire(props.ville.territoire)
        setEdit(props.edit)
    }, [props.ville])

    return (
        <div style={{marginTop: '10vh'}}>
            <div style={styles.closeDiv}>
                <Popup
                    trigger={<Icon name='close' link size="large" onClick={props.cancelForm} />}> 
                    Fermer
                </Popup>
            </div>
            <Form style={styles.form}>
                <Form.Field required>
                    <label>Nom</label>
                    <input type="text" name="nom" placeholder="Nom" value={nom} onChange={e => setNom(e.target.value.toString().toUpperCase())}/>
                </Form.Field>
                <Form.Field required>
                    <label>Code Postal</label>
                    <input type="text" name="code_postal" placeholder="Code Postal" value={code_postal} onChange={e => setCP(e.target.value)}/>
                </Form.Field>
                <Form.Field required>
                    <label>Pays</label>
                    <CountryDropdown
                        value={pays}
                        className="ui fluid dropdown"
                        onChange={(val) => setPays(val)} />
                </Form.Field>
                <Form.Field required>
                    <label>Territoire</label>
                    <select className="ui fluid dropdown" onChange={e => setTerritoire(e.target.value)} value={territoire}>
                        {props.territoire === null ? (<option value=''>Choisir un Territoire</option>) : null }
                        {props.territoires.filter(territoire => {
                            if (props.territoire !== null){
                                if(props.territoire.id === territoire.id){
                                    return territoire
                                }
                            } else {
                                return territoire
                            }
                        }).map(territoire => {
                            return (<option key={territoire.id} value={territoire.id}>{territoire.nom}</option>)
                        })}
                    </select>
                </Form.Field>
                <Button.Group>
                    <Button positive 
                        disabled={nom === '' || code_postal === '' || pays === ''}
                        onClick={props.saveVille({nom: nom, code_postal: code_postal, pays: pays, territoire: territoire})}>Valider</Button>
                    {edit ? (
                        <React.Fragment>
                            <Button.Or />
                            <Button negative onClick={props.deleteVille}>Supprimer la Ville</Button>
                        </React.Fragment>
                    ) : null}
                </Button.Group>
                { props.errors ? (
                    <Message onDismiss={props.cleanErrors} negative>
                        <Message.Header>{props.errors}</Message.Header>
                    </Message>
                ): null}  
            </Form>
        </div>
    )
}


var styles = {
    form: {
        width: '50%',
        marginLeft: '25%',
        textAlign: 'left',
    },
    closeDiv: {
        width: '50%',
        marginLeft: '25%',
        display: "flex",
        justifyContent: 'flex-end'
    },
}