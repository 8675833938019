import './Reader.css';
import React, { Component, useState, useEffect, useDebugValue } from 'react';
import { Container, Dropdown } from 'semantic-ui-react'
import { Player } from 'video-react';
import { withCookies, useCookies } from 'react-cookie';
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery, { Photo } from "react-photo-gallery";
import Navbar from '../Navbar';
import ReactAudioPlayer from 'react-audio-player';
import { CountryDropdown } from 'react-country-region-selector';
const ReactMarkdown = require('react-markdown')



export default function Reader(props){

    const [cookies, setCookie, removeCookie] = useCookies(['FC_token']);
    const [userLangue, setUserLangue] = useState(null)
    const [langues, setLangues] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const [pointInteret, setPointInteret] = useState(null)
    const [article, setArticle] = useState(null)
    const [premiumUser, setPremiumUser] = useState(false)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const [currentImage, setCurrentImage] = useState(0)
    const [userID, setuserId] = useState(null)
    const [langueDropSelect, setLangueDropSelect] = useState('fr')
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        loadLangues();
        if(props.match.params){
            loadUserLangue();
            loadPi(props.match.params.pID);
            getUserId();
            if('FC_premium' in cookies){
                let premium = JSON.parse(cookies['FC_premium'])
                setPremiumUser(premium)
            }
        }
    }, [])

    useEffect(() => {
        if(countryOptions.length > 0 && langues.length > 0){
            let initiales = getLangueInitiales(langueDropSelect)
            if (initiales === 'gb'){
                initiales = 'uk'
            }
            let langue = countryOptions.filter(l => {
                if(l.key == initiales){
                    return l
                }
            })[0]
            let uLang = langues.filter(l => {
                if(langue.id == l.id){
                    return l
                }
            })[0]
            setUserLangue(uLang)    
        }
    }, [langueDropSelect])

    const getUserId = () => {
        // Get user ID from hash key
        let token = cookies['FC_token']
        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/api/users/id_from_token/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            }).then(response => response.json())
            .then(resp => {
                setuserId(resp.user_id)
            })
            .catch(error => console.log(error))
        }
    }

    const loadLangues = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/langues/`, {
            method: 'GET',
        }).then(response => response.json())
          .then(resp => {
            let countryOptions = resp.map(l => {
                let init = l.initiales
                if (init === null){
                    init = 'fr'
                } else {
                    init = init.toLowerCase()
                }
                if(init === 'en-us'){
                    init = 'uk'
                }
                return {key: init, value: init, flag: init, text: l.nom, id: l.id}
            })
            setLangues(resp)
            setCountryOptions(countryOptions)
          })
          .catch(error => console.log(error))
    }

    const loadUserLangue = () => {
        if ("FC_token" in cookies){
            let token = cookies['FC_token']
            fetch(`${process.env.REACT_APP_API_URL}/api/users/get_langue/`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            }).then(response => response.json())
            .then(resp => {
                setUserLangue(resp.langue)
            })
            .catch(error => console.log(error))
        } else {
            setUserLangue({user_langue: {id: 1, nom: "FRANCAIS", initiales: "fr"}})
        }
    }

    const loadPi = (pid) => {
        let method = "GET"
        let url = `${process.env.REACT_APP_API_URL}/api/points_interet/${pid}/`
        let headers = null
        setLoading(true);
        if ("FC_token" in cookies){
            let token = cookies['FC_token']
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        } else {
            headers = {'Content-Type': 'application/json'}
        }
        fetch(url, {
            method: method,
            headers: headers,
        }).then(response => response.json())
        .then(resp => {
            setPointInteret(resp)
            setLoading(false);
        })
        .catch(error => console.log(error))
    }

    const getTrad = article => {
        let trad_titre = article.titre
        let trad_contenu = article.contenu
        if (article.langue !== userLangue.id && article.traductions.length > 0) {
            let trads = article.traductions.filter(trad => trad.langue === userLangue.id)
            if(trads.length > 0){
                trad_titre = trads[0].titre
                trad_contenu = trads[0].contenu
            } 
        }
        let result = {titre: trad_titre, contenu: trad_contenu}

        return result
    }

    const formatPhotos = () => {
        let art = article;
        let legende;
        if(art === null || art === undefined){
            return []
        } else {
            let photos = art.images.map(image => {
                // Si langue de l'utilisateur est la même que celle de l'article, pas de traduction
                if (userLangue.id === article.langue){
                    legende = image.legende
                } else {
                    legende = image.legende
                    // Recup de la trad dans la langue de l'utilisateur
                    let legende_trad = image.traductions_legendes.filter(traduction => traduction.langue.id === userLangue.id)[0]
                    if(legende_trad !== undefined){
                        legende = legende_trad.traduction
                    } else { // Si la trad n'existe pas dans la langue de l'utilisateur, on recup en anglais
                        let legende_trad = image.traductions_legendes.filter(traduction => traduction.langue.nom === "ENGLISH")[0]
                        if(legende_trad !== undefined){
                            legende = legende_trad.traduction
                        } else { // Si pas de trad en anglais, on laisse celle d'origine
                            legende = image.legende
                        }
                    }
                }

                let imgData = {
                    src: image.url,
                    height: image.height,
                    width: image.width,
                    title: legende,
                }
                return imgData
            })
            return photos
        }
    }

    const openLightbox = ({photo, index}) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    const imageRenderer = ({ index, onClick, photo, margin, direction, top, left, key}) => {  
        return (   
          <div>
            <Photo key={key} index={index} photo={photo} left={left} top={top}
                   onClick={onClick} margin={margin} direction={direction} />
           </div>
        )
    }

    const selectArticle = (article) => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setArticle(article)
    }

    const getLangueInitiales = (langue) => {
        let init = langue.initiales
        if (init === null){
            init = 'fr'
        } else {
            init = init.toLowerCase()
        }
        if(init === 'uk' || init === 'en-us'){
            init = 'gb'
        }
        return init   
    }


    return (
        <div id="reader-container" style={styles.readerContainer}>
            <div style={{
                position: 'fixed',  
                top: 0, 
                zIndex: 1,
                width: '100%',
                display: 'flex', 
                justifyContent: "center", 
                alignContent:"center", 
                paddingTop:"1rem", 
                paddingBottom:"1rem", 
                backgroundColor: 'rgb(17,128,128)'
            }}>
                <h1 className="ui header" style={{color: 'white'}}>QUESAQUO</h1>
            </div>
            <div className="ui container">
                {pointInteret !== null && pointInteret.active ? ( 
                <div>
                    {pointInteret !== null && article === null &&  userLangue !== null ? (
                        <div className="grid ui" style={styles.article_list}>
                            {pointInteret.articles.map(article => {
                                let trad = getTrad(article);
                                let map_titre = trad.titre;
                                let style = {}
                                // Visible pour l'utilisateur qui écrit l'article
                                if (!article.published && userID !== article.users[0]) {
                                    style.display = 'none';
                                }
                                return (
                                    <div className="ui raised card placeholder" key={article.id} style={{width: '80%'}} onClick={() => selectArticle(article)}>
                                        <div className="ui fluid image">
                                            {article.images.length > 0 ? (
                                                <img src={article.images[0].url}/>
                                            ) : (
                                                <img src="https://i.ebayimg.com/images/g/B~gAAOSwhNthhdjn/s-l1200.webp" />
                                            )}
                                        </div>
                                        <div className="content">
                                            <a className="header">{map_titre}</a>
                                        </div>
                                        <div className="ui bottom attached button teal" >
                                            LIRE
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div id="article-read">
                            {article !== null ? (
                                <div>
                                    <div className="article-title" style={styles.articleTitle}>
                                        <h1 className="ui header" style={styles.articlHeader}>{getTrad(article).titre}</h1>
                                    </div>
                                    {article.audios.length > 0 ? (
                                        <div style={styles.articleAudio}>
                                            {article.audios.map(audio => {
                                                return (
                                                    <div key={audio.id}>
                                                        <h3 style={{textAlign: 'center'}}>{audio.titre}</h3>
                                                        <ReactAudioPlayer src={audio.url} controls controlsList="nodownload"/> 
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ): null}
                                    <div className="article-contenu ui container text" style={styles.mdText}>
                                        <ReactMarkdown source={getTrad(article).contenu} />
                                    </div>
                                    {article.images.length > 0 ?(
                                        <div style={styles.imageGallery}>
                                            <Gallery photos={formatPhotos()} onClick={openLightbox} renderImage={imageRenderer}/>
                                            <ModalGateway>
                                                {viewerIsOpen ? (
                                                <Modal onClose={closeLightbox}>
                                                    <Carousel
                                                        currentIndex={currentImage}
                                                        modalStyles={modalStyles}
                                                        views={formatPhotos().map(x => ({
                                                            ...x,
                                                            srcset: x.src,
                                                            caption: x.title
                                                        }))}
                                                    />
                                                </Modal>
                                                ) : null}
                                            </ModalGateway>
                                        </div>
                                    ): null}
                                    {article.videos.length > 0 ? (
                                        <div style={styles.articleVideo}>
                                            {article.videos.map(video => {
                                                return ( 
                                                    <div key={video.id}>
                                                        <h3 style={{textAlign: 'center'}}>{video.titre}</h3>
                                                        <Player playsInline src={video.url}  />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ): null}
                                </div>
                            ) : null }
                        </div>
                    )}
                </div>
                ): null}

                {!isLoading & pointInteret === null ? (
                    <Container text>
                        Ce Point d'interet n'est malheuresement plus disponible pour le moment.
                    </Container>
                ) : null}

                <div style={{
                    position: 'fixed',  
                    bottom: 0, 
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    borderTop: '1px solid rgb(17,128,128)',
                    backgroundColor: 'white',

                }}>
                    {article !== null ? (
                    <div style={styles.bottomMenuItem} onClick={ e => {setArticle(null)}}>
                        <i key="retour" className="arrow left icon big" style={styles.bottomMenuIcon}></i>
                        <p>Retour</p>
                    </div>
                    ): null}
                    <div style={styles.bottomMenuItem} onClick={ e => {window.location.href = "/explore"}}>
                        <i key="carte" className="map icon big" style={styles.bottomMenuIcon}></i>
                        <p>Carte</p>
                    </div>
                    <div style={styles.bottomMenuItem}>
                        <i key="carte" className="language icon big" style={styles.bottomMenuIcon}></i>
                        <div>
                            {!isLoading & userLangue !== null ? (
                                <Dropdown text="Langue">
                                    <Dropdown.Menu>
                                        {langues.map(lang => (
                                            <Dropdown.Item
                                                key={lang.initiales}
                                                flag={{ name: getLangueInitiales(lang), }}
                                                value={lang.initiales}
                                                onClick={() => setLangueDropSelect(lang)}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

var styles = {
    imageGallery: {
        width: "70%",   
        margin: '10vh 15%',
    },
    articleVideo: {
        width: '70%',
        margin: '5vh 15%',
    },
    articleAudio: {
        width: '30%',
        margin: '5vh 35%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
    },
    article_list: {
        marginTop: '10vh',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        paddingBottom: '10vh',
    },
    backArrowDiv: {
        margin: '0 5em',
    },
    articleTitle: {
        paddingTop: '5vh',
        width: "70%",   
        margin: '2vh 15%',
    },
    centeredButton:{
        width: '50%',
        marginLeft: '25%'
    },
    articlHeader: {
        // fontSize: '1.5em',
        // fontSize: '4vw',
    },
    mdText: {
        width: "70%",   
        margin: '2vh 15%',
        textAlign: 'justify',
    },
    arrowLeft: {
        float: 'left',
        cursor: 'pointer',
        // fontSize: '3rem',
        // fontSize: '3vw',
        marginLeft: '2em'
    },
    readerContainer: {
        paddingBottom: '5vh',
    },
    bottomMenuItem: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomMenuIcon: {
        color: 'rgb(17,128,128)',
    },
}

var modalStyles = {
    header: (base, state) => ({
        ...base,
        borderBottom: '1px dotted pink',
        color: state.isFullscreen ? 'red' : 'blue',
        padding: 20,
      }),
      view: () => ({
        // none of react-images styles are passed to <View />
        height: 400,
        width: 600,
      }),
      footer: (base, state) => {
        const opacity = state.interactionIsIdle ? 0 : 1;
        const transition = 'opacity 300ms';
    
        return { ...base, opacity, transition };
    }
}