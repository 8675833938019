import React, { useState, useEffect } from 'react';
import { Popup, Icon, Dimmer, Loader, Message, Item,  CardGroup, Card, CardHeader, CardDescription, CardContent, Button } from 'semantic-ui-react'


export default function TableArticle(props){

    const [articles, setArticles] = useState([])

    useEffect(() => {
        setArticles(props.articles)
    }, [props.articles])

    return (
        <React.Fragment>
            {articles !== null ? (
                <div className="ressouce_table">
                    {articles.length === 0 ? (
                        <Message>
                            <Message.Header>Vous n'avez pas encore d'articles.</Message.Header>
                        </Message>  
                    ) : (
                        <div style={{ textAlign: 'left'}}>
                            <CardGroup>
                                {articles.map(article => {
                                    return <Card fluid color='#118080' >
                                        <CardContent onClick={e => {props.readArticle(article)}} style={{cursor: 'pointer'}}>
                                            <CardHeader>{article.titre}</CardHeader>
                                            <CardDescription>
                                                <p>Langue d'origine: {props.getNomLangue(article)}</p>
                                                <p>Traductions: {article.traductions.length}</p>
                                                <p>Visible: {article.published ? 'Oui' : 'Non'}</p>
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent extra>
                                            <div className='ui two buttons'>
                                                <Button basic color='green' onClick={props.editArticle(article)}>
                                                    Modifier
                                                </Button>
                                                <Button basic color='blue' onClick={props.toggleTranslate(article)}>
                                                    Traductions
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                })}
                            </CardGroup>
                        </div>
                    )}
                </div>
            ) : (
                <Dimmer active inverted>
                    <Loader inverted>Loading Articles</Loader>
                </Dimmer>
            )}
            
        </React.Fragment>
    )

}

var styles = {
    editIcon: {
        marginLeft: '1em',
    },
    spanHeader: {
        cursor: 'pointer',
    }
}